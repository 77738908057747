import { useEffect, useState } from 'react'
import APIError from '../../resources/error'
import { useEstablishment } from './useEstablishment'
import { ReminderNotebook } from '../../resources/establishment/types'
import { useGetReminderNotebooks } from './useGetReminderNotebook'

export const useReminderNotebooks = (establishmentId: number, initialDate?: string) => {
    const establishment = useEstablishment(establishmentId)

    const [error, setError] = useState<APIError>()
    const { getReminderNotebooks, inProgress } = useGetReminderNotebooks()

    let isAlreadyLoaded = false
    let reminderNotebooks: Array<ReminderNotebook> = []
    if (establishment !== undefined) {
        if (establishment.reminderNotebooks) {
            reminderNotebooks = establishment.reminderNotebooks
            isAlreadyLoaded = true
        }
    }
    const [isReady, setIsReady] = useState<boolean>(isAlreadyLoaded)

    useEffect(() => {
        if (!isReady && !inProgress && establishment !== undefined && error === undefined) {
            if (establishment.reminderNotebooks === undefined) {
                getReminderNotebooks({
                    id: establishmentId,
                    date: initialDate,
                })
                    .then(() => {
                        setIsReady(true)
                    })
                    .catch((error) => {
                        setError(error)
                        setIsReady(true)
                    })
            }
        }
    }, [isReady, establishment, inProgress, error, getReminderNotebooks, initialDate, establishmentId])

    return {
        isReady,
        reminderNotebooks,
        error,
        refreshReminderNotebooks: getReminderNotebooks,
        inProgress,
    }
}
