import { useState } from 'react'
import { useDispatch } from '../redux'
import { FetchContent, MessagingConversation, SetMessagingMessage } from '../../resources/messages/types'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'
import { setMessagingMessage, setPostDetails } from '../../store/messaging/actions'

export const useGetContent = () => {
    const [inProgress, setInProgress] = useState(false)
    const dispatch = useDispatch()

    const getContent = (payload: MessagingConversation): Promise<FetchContent> => {
        setInProgress(true)
        return queries
            .getPostContent(payload)
            .then((response) => {
                // store messages
                const objSetMessagingMessage: SetMessagingMessage = {
                    conversation: payload.id,
                    messages: response.messages,
                }
                dispatch(setMessagingMessage(objSetMessagingMessage))
                if (response.postDetails) {
                    dispatch(setPostDetails(response.postDetails))
                }

                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        getContent,
        inProgress,
    }
}
