import { call, put, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import { actions } from '../actions'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'
import { CurrentSlateResponse } from '../../resources/establishment/types'
import { AxiosError } from 'axios'

function* slateSaga() {
    yield takeLatest(getType(actions.updateSlate), updateSlate)
    yield takeLatest(getType(actions.shareSlate), shareSlate)
}

export function* updateSlate(action: ActionType<typeof actions.updateSlate>) {
    try {
        const response: CurrentSlateResponse = yield call(mutations.updateSlate, action.payload)
        yield put(actions.setCurrentSlate(response))
    } catch (error) {
        throw generateError(error as AxiosError)
    }
}

export function* shareSlate(action: ActionType<typeof actions.shareSlate>) {
    try {
        const response: CurrentSlateResponse = yield call(mutations.shareSlate, action.payload)
        yield put(actions.setCurrentSlate(response))
    } catch (error) {
        throw generateError(error as AxiosError)
    }
}

export default slateSaga
