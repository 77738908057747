import { AxiosResponse } from 'axios'
import { SuggPro } from '../../client/suggPro'
import { stringToDate } from '../../helpers/dateConversion'
import { convertToEstablishment } from './helpers'
import {
    ConnectToFacebookPageRequest,
    ConnectToGooglePageRequest,
    CreateEstablishmentPicturesRequest,
    CreateMenuEntriesRequest,
    DayOfWeek,
    DeleteEstablishmentPictureRequest,
    DeleteMenuEntryRequest,
    DeleteMenuEntryResponse,
    DeleteSpecialClosingRequest,
    Establishment,
    EstablishmentPicture,
    EstablishmentPicturesResponse,
    EstablishmentRedeemCodeRequest,
    EstablishmentRetrieveStripeCheckoutRequest,
    EstablishmentUseNumericCheckRequest,
    InitializeConnectToTwitterResponse,
    MenuEntriesResponse,
    MenuEntry,
    SpecialClosing,
    SpecialClosingRequest,
    TwitterInfoRequest,
    TwitterInfoResponse,
    UpdateEstablishmentPicturesRequest,
    UpdateEstablishmentRequest,
    UpdateEstablishmentWebsiteSectionRequest,
    UpdateEstablishmentWebsiteSectionResponse,
    UpdateMenuEntriesRequest,
    WebsiteSection,
} from './types'

export const createMenuEntries = (parameters: CreateMenuEntriesRequest): Promise<MenuEntriesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    for (let i = 0; i < parameters.files.length; i++) {
        const file = parameters.files[i]
        bodyFormData.append('file', file)
    }

    const url = '/establishment/:id/menu_entries'.replace(':id', parameters.id.toString())

    return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<Array<MenuEntry>>) => {
        if (result.data) {
            return {
                id: parameters.id,
                menuEntries: result.data,
            }
        }
        throw Error()
    })
}

export const deleteMenuEntry = (parameters: DeleteMenuEntryRequest): Promise<DeleteMenuEntryResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/menu_entries/:pictureId'
        .replace(':id', parameters.id.toString())
        .replace(':pictureId', parameters.pictureId.toString())

    return axiosInstance.delete(url).then((result: AxiosResponse<void>) => {
        if (result.status === 204) {
            return parameters
        }
        throw Error()
    })
}

export const updateMenuEntries = (parameters: UpdateMenuEntriesRequest): Promise<MenuEntriesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/menu_entries'.replace(':id', parameters.id.toString())

    const bodyFormData = new FormData()
    for (let i = 0; i < parameters.sortOrders.length; i++) {
        const menuEntryId = parameters.sortOrders[i]
        bodyFormData.append('sortOrder[]', menuEntryId.toString())
    }
    return axiosInstance.patch(url, bodyFormData).then((result: AxiosResponse<Array<MenuEntry>>) => {
        if (result.data) {
            return {
                id: parameters.id,
                menuEntries: result.data,
            }
        }
        throw Error()
    })
}

export const connectToFacebookPage = (parameters: ConnectToFacebookPageRequest): Promise<Establishment> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const data: any = {
        pageId: parameters.pageId,
        facebookSync: parameters.facebookSync,
    }
    if (parameters.facebookAccessToken) {
        data.facebookAccessToken = parameters.facebookAccessToken
    }
    if (parameters.updateEstablishment) {
        data.updateEstablishment = true
    }

    const url = '/establishment/:id/facebook/connect'.replace(':id', parameters.id.toString())

    return axiosInstance
        .post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<Establishment>) => {
            return convertToEstablishment(result.data)
        })
}

export const connectToGooglePage = (parameters: ConnectToGooglePageRequest): Promise<Establishment> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const data: any = {
        idGoogleLocation: parameters.idGoogleLocation,
        googleSync: parameters.googleSync,
    }
    if (parameters.googleAccessToken) {
        data.facebookAccessToken = parameters.googleAccessToken
    }
    if (parameters.updateEstablishment) {
        data.updateEstablishment = true
    }

    const url = '/establishment/:id/google/connect'.replace(':id', parameters.id.toString())

    return axiosInstance
        .post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<Establishment>) => {
            return convertToEstablishment(result.data)
        })
}

export const updateEstablishment = (parameters: UpdateEstablishmentRequest): Promise<Establishment> => {
    const axiosInstance = SuggPro.getAxiosInstance()
    const establishment = parameters.establishment
    let businessHours = null
    if (establishment.businessHours) {
        businessHours = {
            monday: establishment.businessHours[DayOfWeek.Monday],
            tuesday: establishment.businessHours[DayOfWeek.Tuesday],
            wednesday: establishment.businessHours[DayOfWeek.Wednesday],
            thursday: establishment.businessHours[DayOfWeek.Thursday],
            friday: establishment.businessHours[DayOfWeek.Friday],
            saturday: establishment.businessHours[DayOfWeek.Saturday],
            sunday: establishment.businessHours[DayOfWeek.Sunday],
        }
    }
    const data = {
        ...parameters.establishment,
        nextStatus: parameters.nextStatus,
        businessHours: establishment.businessHours ? businessHours : undefined,
    }

    const url = '/establishment/:id'.replace(':id', parameters.id.toString())

    return axiosInstance
        .put(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<Establishment>) => {
            return convertToEstablishment(result.data)
        })
}

export const createEstablishmentPictures = (
    parameters: CreateEstablishmentPicturesRequest,
): Promise<EstablishmentPicturesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    for (let i = 0; i < parameters.files.length; i++) {
        const file = parameters.files[i]
        bodyFormData.append('file', file)
    }

    const url = '/establishment/:id/pictures'.replace(':id', parameters.id.toString())

    return axiosInstance
        .post(url, bodyFormData)
        .then((result: AxiosResponse<Array<EstablishmentPicture>>) => {
            if (result.data) {
                return {
                    id: parameters.id,
                    pictures: result.data,
                }
            }
            throw Error()
        })
}

export const updateEstablishmentPictures = (
    parameters: UpdateEstablishmentPicturesRequest,
): Promise<EstablishmentPicturesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/pictures'.replace(':id', parameters.id.toString())

    const bodyFormData = new FormData()
    for (let i = 0; i < parameters.sortOrders.length; i++) {
        const establishmentPictureId = parameters.sortOrders[i]
        bodyFormData.append('sortOrder[]', establishmentPictureId.toString())
    }
    if (parameters.mainPicture !== undefined) {
        bodyFormData.append('mainPicture', parameters.mainPicture.toString())
    }
    return axiosInstance
        .patch(url, bodyFormData)
        .then((result: AxiosResponse<Array<EstablishmentPicture>>) => {
            if (result.data) {
                return {
                    id: parameters.id,
                    pictures: result.data,
                }
            }
            throw Error()
        })
}

export const updateEstablishmentWebsiteSection = (
    parameters: UpdateEstablishmentWebsiteSectionRequest,
): Promise<UpdateEstablishmentWebsiteSectionResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/website_sections'.replace(':id', parameters.establishment.toString())

    const bodyFormData = new FormData()
    bodyFormData.append('section', parameters.section)
    bodyFormData.append('establishment', parameters.establishment.toString())

    if (parameters.id !== undefined) {
        bodyFormData.append('id', parameters.id.toString())
    }

    if (parameters.textColor !== null) {
        bodyFormData.append('textColor', parameters.textColor)
    } else {
        bodyFormData.append('textColor', 'null')
    }

    if (parameters.backgroundColor !== null) {
        bodyFormData.append('backgroundColor', parameters.backgroundColor)
    } else {
        bodyFormData.append('backgroundColor', 'null')
    }

    if (parameters.backgroundImage) {
        bodyFormData.append('backgroundImage', parameters.backgroundImage)
    } else if (parameters.backgroundImage === null) {
        bodyFormData.append('backgroundImage', 'null')
    }

    if (parameters.id !== undefined) {
        return axiosInstance.patch(url, bodyFormData).then((result: AxiosResponse<WebsiteSection>) => {
            if (result.data) {
                return {
                    id: parameters.establishment,
                    websiteSection: result.data,
                }
            }
            throw Error()
        })
    } else {
        return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<WebsiteSection>) => {
            if (result.data) {
                return {
                    id: parameters.establishment,
                    websiteSection: result.data,
                }
            }
            throw Error()
        })
    }
}

export const deleteEstablishmentPictures = (parameters: DeleteEstablishmentPictureRequest): Promise<void> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/pictures/:id_picture'
        .replace(':id', parameters.id.toString())
        .replace(':id_picture', parameters.pictureId.toString())

    return axiosInstance.delete(url)
}

export const establishmentRetrieveStripeCheckout = ({
    id,
    ...parameters
}: EstablishmentRetrieveStripeCheckoutRequest): Promise<string> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/checkout'.replace(':id', id.toString())

    return axiosInstance
        .post(url, parameters, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<string>) => {
            return result.data
        })
}

export const establishmentUseNumericCheck = (
    parameters: EstablishmentUseNumericCheckRequest,
): Promise<string> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    bodyFormData.append('code', parameters.code)

    const url = '/establishment/:id/numeric_check_init'.replace(':id', parameters.id.toString())

    return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<string>) => {
        return result.data
    })
}

export const establishmentRedeemCode = (
    parameters: EstablishmentRedeemCodeRequest,
): Promise<Establishment> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/redeem/:code'
        .replace(':id', parameters.id.toString())
        .replace(':code', parameters.code)

    return axiosInstance.post(url).then((result: AxiosResponse<Establishment>) => {
        return result.data
    })
}

export const initializeConnectToTwitter = (): Promise<AxiosResponse<InitializeConnectToTwitterResponse>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance.post('/establishment/twitter/start')
}

export const getTwitterInfo = (
    parameters: TwitterInfoRequest,
): Promise<AxiosResponse<TwitterInfoResponse>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance.post('/establishment/twitter/info', parameters, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const deleteSpecialClosing = (
    parameters: DeleteSpecialClosingRequest,
): Promise<AxiosResponse<void>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/special_closing/:num'
        .replace(':id', parameters.establishment.toString())
        .replace(':num', parameters.id.toString())

    return axiosInstance.delete(url)
}

export const createSpecialClosing = (
    parameters: SpecialClosingRequest,
): Promise<AxiosResponse<SpecialClosing>> => {
    const axiosInstance = SuggPro.getAxiosInstance()
    const bodyFormData = new FormData()
    bodyFormData.append('label', parameters.label)
    bodyFormData.append('dateStart', stringToDate(parameters.dateStart))
    bodyFormData.append('dateEnd', stringToDate(parameters.dateEnd))
    bodyFormData.append('establishment', parameters.establishment.toString())

    const url = '/establishment/:id/special_closing'.replace(':id', parameters.establishment.toString())

    return axiosInstance.post(url, bodyFormData)
}
