import { createAction } from 'typesafe-actions'
import { prefix } from '../../config/config'
import {
    DeleteConversation,
    DeleteMessage,
    MessagingConversation,
    NewMessagingMessage,
    PostDetails,
    PostMessageWithAuthor,
    SetMessagingMessage,
} from '../../resources/messages/types'
import { MessagesActionTypes, MessagingStatus, SetMessagingConversation } from './types'
import { Establishment } from '../../resources/establishment/types'

const actionPrefix = '@@' + prefix + '/'

export const setMessagingConversation = createAction(
    actionPrefix + MessagesActionTypes.SET_MESSAGING_CONVERSATION,
)<SetMessagingConversation>()

export const setMessagingMessage = createAction(actionPrefix + MessagesActionTypes.SET_MESSAGING_MESSAGE)<
    SetMessagingMessage
>()

export const patchMessagingConversation = createAction(
    actionPrefix + MessagesActionTypes.PATCH_MESSAGING_CONVERSATION,
)<MessagingConversation>()

export const addMessagingMessage = createAction(actionPrefix + MessagesActionTypes.ADD_MESSAGING_MESSAGE)<
    NewMessagingMessage
>()

export const deleteMessagingConversations = createAction(
    actionPrefix + MessagesActionTypes.DELETE_MESSAGING_CONVERSATIONS,
)<{
    prev_state: Establishment
    next_state: Establishment
}>()

export const deleteMessagingMessage = createAction(
    actionPrefix + MessagesActionTypes.DELETE_MESSAGING_MESSAGE,
)<DeleteMessage>()

export const hideMessagingMessage = createAction(actionPrefix + MessagesActionTypes.HIDE_MESSAGING_MESSAGE)<
    DeleteMessage
>()

export const cleanMessaging = createAction(actionPrefix + MessagesActionTypes.CLEAN_MESSAGING)<undefined>()

export const addMessagingConversation = createAction(
    actionPrefix + MessagesActionTypes.ADD_MESSAGING_CONVERSATION,
)<MessagingConversation>()

export const messagingConversationReady = createAction(
    actionPrefix + MessagesActionTypes.MESSAGING_CONVERSATION_READY,
)<NewMessagingMessage>()

export const deleteMessagingConversation = createAction(
    actionPrefix + MessagesActionTypes.DELETE_MESSAGING_CONVERSATION,
)<DeleteConversation>()

export const setMessagingStatus = createAction(actionPrefix + MessagesActionTypes.SET_MESSAGING_STATUS)<
    MessagingStatus
>()

export const updateMessagingStatus = createAction(actionPrefix + MessagesActionTypes.UPDATE_MESSAGING_STATUS)<
    MessagingStatus
>()

export const setPostDetails = createAction(actionPrefix + MessagesActionTypes.SET_POST_DETAILS)<PostDetails>()

export const createMessagingMessage = createAction(
    actionPrefix + MessagesActionTypes.CREATE_MESSAGING_MESSAGE,
)<PostMessageWithAuthor>()

export const updateMessagingConversation = createAction(
    actionPrefix + MessagesActionTypes.UPDATE_MESSAGING_CONVERSATION,
)<MessagingConversation>()
