import actions from '../../store/actions'
import { useEffect, useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'
import { GetTypesRequest } from '../../resources/establishment/types'
import { useDispatch, useSelector } from '../redux'
import { SuggProState } from '../../store'
import APIError from '../../resources/error'

export const useEstablishmentTypes = () => {
    const dispatch = useDispatch()
    const { establishmentTypes } = useSelector(
        ({ suggpro: { establishment } }: { suggpro: SuggProState }) => ({
            establishmentTypes: establishment.establishmentTypes,
        }),
    )
    const [error, setError] = useState<APIError>()
    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
        const getTypes = (payload: GetTypesRequest): Promise<void> => {
            setInProgress(true)
            return queries
                .getTypes(payload)
                .then((response) => {
                    dispatch(actions.setEstablishmentTypes(response))
                    setInProgress(false)
                })
                .catch((error) => {
                    throw generateError(error)
                })
        }

        if (establishmentTypes === undefined) {
            getTypes({})
                .then((_) => {
                    // ignore
                })
                .catch((error) => setError(error))
        }
    }, [dispatch, establishmentTypes])

    return {
        establishmentTypes,
        inProgress,
        error,
    }
}
