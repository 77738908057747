import { put, select, take, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { SuggPro } from '../../client/suggPro'
import { storagePrefix } from '../../config/config'
import { User } from '../../resources/auth/types'
import { MenuEntry } from '../../resources/establishment/types'
import { ApplicationState } from '../reducers'

function* sessionSaga() {
    yield takeLatest(getType(actions.start), loadSession)
    yield takeLatest(getType(actions.signIn), saveSessionSignIn)
    yield takeLatest(getType(actions.setUser), saveSessionUser)
    yield takeLatest(getType(actions.setEstablishment), saveSessionUserEstablishment)
    yield takeLatest(getType(actions.setEstablishmentTypes), saveSessionEstablishmentTypes)
    yield takeLatest(getType(actions.setCategories), saveSessionSlateCategories)
    yield takeLatest(getType(actions.setCurrentSlate), saveSessionUserCurrentSlate)
    yield takeLatest(getType(actions.setMenuEntries), saveSessionUserMenuEntries)
    yield takeLatest(getType(actions.deleteMenuEntry), saveSessionUserDeleteMenuEntry)
    // yield takeLatest(getType(actions.refresh), saveSessionRefresh)
    yield takeLatest(getType(actions.verifyEmail), saveSessionVerifyEmail)
    yield takeLatest(getType(actions.signOut), destroySession)

    yield put(actions.start(false))
}

function* loadSession(action: ActionType<typeof actions.start>) {
    const started = action.payload
    if (!started) {
        const persistentStorage = SuggPro.getPersistentStorage()

        const access: string | null = yield persistentStorage.getItem(storagePrefix + '/auth/access')
        const refresh: string | null = yield persistentStorage.getItem(storagePrefix + '/auth/refresh')

        if (access !== null && refresh !== null) {
            try {
                yield put(actions.signIn({ access: access, refresh: refresh, withProgress: true }))
                yield take(actions.setSignInInProgressEnd)
            } catch (error) {
                yield destroyBrowserStorage(true)
                yield put(actions.signOut())
            }
        }
        yield put(actions.start(true))
    }
}

function* saveSessionSignIn(action: ActionType<typeof actions.signIn>) {
    const { access, refresh } = action.payload
    const sessionStorage = SuggPro.getSessionStorage()
    const persistentStorage = SuggPro.getPersistentStorage()

    yield sessionStorage.setItem(storagePrefix + '/auth/access', access !== null ? access : '')
    yield persistentStorage.setItem(storagePrefix + '/auth/refresh', refresh !== null ? refresh : '')
}

function* saveSessionUser(action: ActionType<typeof actions.setUser>) {
    const user = action.payload
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/auth/user', user !== null ? JSON.stringify(user) : '')
}

function* saveSessionUserEstablishment(action: ActionType<typeof actions.setEstablishment>) {
    const user: User = yield select(({ suggpro: { auth } }: ApplicationState) => auth.user)
    const establishmentResponse = action.payload
    const { establishments } = user
    if (establishments !== undefined) {
        const establishmentIndex = establishments.findIndex(
            (establishment) => establishment.id === establishmentResponse.id,
        )
        if (establishmentIndex !== -1) {
            establishments[establishmentIndex] = {
                ...establishments[establishmentIndex],
                ...establishmentResponse,
            }
        }
        user.establishments = establishments
    }
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/auth/user', user !== null ? JSON.stringify(user) : '')
}

function* saveSessionEstablishmentTypes(action: ActionType<typeof actions.setEstablishmentTypes>) {
    const establishmentTypes = action.payload
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/establishments/type', JSON.stringify(establishmentTypes))
}

function* saveSessionSlateCategories(action: ActionType<typeof actions.setCategories>) {
    const slateCategories = action.payload
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/slates/categories', JSON.stringify(slateCategories))
}

function* saveSessionUserCurrentSlate(action: ActionType<typeof actions.setCurrentSlate>) {
    const user: User = yield select(({ suggpro: { auth } }: ApplicationState) => auth.user)
    const currentSlateResponse = action.payload
    const { establishments } = user
    if (establishments !== undefined) {
        const establishmentIndex = establishments.findIndex(
            (establishment) => establishment.id === currentSlateResponse.id,
        )
        if (establishmentIndex !== -1) {
            establishments[establishmentIndex].slate = currentSlateResponse.slate || null
        }
        user.establishments = establishments
    }
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/auth/user', user !== null ? JSON.stringify(user) : '')
}

function* saveSessionUserMenuEntries(action: ActionType<typeof actions.setMenuEntries>) {
    const user: User = yield select(({ suggpro: { auth } }: ApplicationState) => auth.user)
    const menuEntriesResponse = action.payload
    const { establishments } = user
    if (establishments !== undefined) {
        const establishmentIndex = establishments.findIndex(
            (establishment) => establishment.id === menuEntriesResponse.id,
        )
        if (establishmentIndex !== -1) {
            establishments[establishmentIndex].menuEntries = menuEntriesResponse.menuEntries || []
        }
        user.establishments = establishments
    }
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/auth/user', user !== null ? JSON.stringify(user) : '')
}

function* saveSessionUserDeleteMenuEntry(action: ActionType<typeof actions.deleteMenuEntry>) {
    const user: User = yield select(({ suggpro: { auth } }: ApplicationState) => auth.user)
    const deleteMenuEntryResponse = action.payload
    const { establishments } = user
    if (establishments !== undefined) {
        const establishmentIndex = establishments.findIndex(
            (establishment) => establishment.id === deleteMenuEntryResponse.id,
        )
        if (establishmentIndex !== -1) {
            const menuEntries: Array<MenuEntry> = establishments[establishmentIndex].menuEntries || []
            const pictureIndex = menuEntries.findIndex(
                (menuEntry) => menuEntry.id === deleteMenuEntryResponse.pictureId,
            )
            if (pictureIndex !== -1) {
                menuEntries.splice(pictureIndex, 1)
            }
        }
        user.establishments = establishments
    }
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/auth/user', user !== null ? JSON.stringify(user) : '')
}

function* saveSessionVerifyEmail() {
    const { user } = yield select(({ suggpro: { auth } }: ApplicationState) => ({
        user: auth.user,
    }))
    const sessionStorage = SuggPro.getSessionStorage()

    yield sessionStorage.setItem(storagePrefix + '/auth/user', user !== null ? JSON.stringify(user) : '')
}

function* destroySession() {
    const sessionStorage = SuggPro.getSessionStorage()
    const persistentStorage = SuggPro.getPersistentStorage()

    yield sessionStorage.removeItem(storagePrefix + '/auth/access')
    yield sessionStorage.removeItem(storagePrefix + '/auth/user')
    yield persistentStorage.removeItem(storagePrefix + '/auth/refresh')
}

function* destroyBrowserStorage(shouldDestroySession: boolean = true) {
    const sessionStorage = SuggPro.getSessionStorage()
    if (shouldDestroySession) {
        yield destroySession()
    } else {
        yield sessionStorage.removeItem(storagePrefix + '/auth/access')
        yield sessionStorage.removeItem(storagePrefix + '/auth/user')
    }

    yield sessionStorage.removeItem(storagePrefix + '/establishments/type')
    yield sessionStorage.removeItem(storagePrefix + '/slates/categories')
}

export default sessionSaga
