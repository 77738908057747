import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { Store } from 'redux'
import { SuggProdContext } from '../store/context'
import { ClientOptions } from '../client/options'
import { SuggPro } from '../client/suggPro'

interface Props {
    store: Store
    options?: ClientOptions
    onInit?: (instance: SuggPro) => void
}

export const SuggProProvider: React.FC<Props> = ({ store, options, children, onInit }) => {
    useEffect(() => {
        if (options) {
            const suggPro = SuggPro.getInstance(options)
            if (onInit) {
                onInit(suggPro)
            }
        }
    }, [onInit, options])

    return (
        <Provider store={store} context={SuggProdContext}>
            {children}
        </Provider>
    )
}
