import { Reducer } from 'redux'
import { StripeState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { Plan } from '../../resources/stripe/types'

const initialState: StripeState = {}

const reducer: Reducer<StripeState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setPlans): {
            const plans: Array<Plan> = action.payload
            return {
                ...state,
                plans,
            }
        }
        default:
            return state
    }
}

export { reducer as stripeReducer }
