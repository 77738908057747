import { SuggPro } from '../../client/suggPro'
import { CreateSlateRequest, Slate, UpdateSlateRequest } from './types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import { CreateSlateResponse, CurrentSlateResponse } from '../establishment/types'
import { getCurrentSlate } from '../establishment/queries'

export const createSlate = (parameters: CreateSlateRequest): Promise<CreateSlateResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    if (parameters.file) {
        bodyFormData.append('file', parameters.file)
    }
    if (parameters.files) {
        parameters.files.forEach((image, index) => {
            if (image.file) {
                bodyFormData.append('files[' + index + '][file]', image.file)
            }
            if (image.comment) {
                bodyFormData.append('files[' + index + '][comment]', image.comment)
            }
            bodyFormData.append(
                'files[' + index + '][isFacebookShared]',
                image.isFacebookShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isGoogleShared]',
                image.isGoogleShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isInstagramShared]',
                image.isInstagramShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isTwitterShared]',
                image.isTwitterShared ? 'true' : 'false',
            )
        })
    }
    bodyFormData.append('validityDate', moment(parameters.validityDate).format('YYYY-MM-DDThh:mm:ssZ'))
    bodyFormData.append('comment', parameters.comment)
    for (let i = 0; i < parameters.categories.length; i++) {
        const category = parameters.categories[i]
        bodyFormData.append('categories', category.id.toString())
    }
    if (parameters.shouldDelete) {
        bodyFormData.append('shouldDelete', 'true')
    }

    const url = '/establishment/:id/create_menuboard'.replace(':id', parameters.establishmentId.toString())

    return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<Slate>) => {
        if (result.data) {
            return {
                id: parameters.establishmentId,
                slate: result.data,
                lastSlate: result.data,
            }
        }
        throw Error()
    })
}

export const updateSlate = (parameters: UpdateSlateRequest): Promise<CurrentSlateResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    if (parameters.validityDate !== undefined) {
        bodyFormData.append('validityDate', moment(parameters.validityDate).format('YYYY-MM-DDThh:mm:ssZ'))
    }

    bodyFormData.append('comment', parameters.comment ?? '')

    if (parameters.isGoogleShared !== undefined) {
        if (parameters.isGoogleShared && parameters.googleAccessToken !== undefined) {
            bodyFormData.append('isGoogleShared', 'true')
            bodyFormData.append('googleAccessToken', parameters.googleAccessToken)
        } else if (!parameters.isGoogleShared) {
            bodyFormData.append('isGoogleShared', 'false')
        }
    }

    if (parameters.isFacebookShared !== undefined) {
        if (parameters.isFacebookShared && parameters.facebookAccessToken !== undefined) {
            bodyFormData.append('isFacebookShared', 'true')
            bodyFormData.append('facebookAccessToken', parameters.facebookAccessToken)
        } else if (!parameters.isFacebookShared) {
            bodyFormData.append('isFacebookShared', 'false')
        }
    }

    if (parameters.isInstagramShared !== undefined) {
        if (parameters.isInstagramShared) {
            bodyFormData.append('isInstagramShared', 'true')
        } else {
            bodyFormData.append('isInstagramShared', 'false')
        }
    }

    if (parameters.isTwitterShared !== undefined) {
        if (parameters.isTwitterShared) {
            bodyFormData.append('isTwitterShared', 'true')
        } else {
            bodyFormData.append('isTwitterShared', 'false')
        }
    }

    if (parameters.categories !== undefined) {
        for (let i = 0; i < parameters.categories.length; i++) {
            const category = parameters.categories[i]
            bodyFormData.append('categories', category.id.toString())
        }
    }

    if (parameters.images) {
        parameters.images.forEach((image, index) => {
            bodyFormData.append('images[' + index + '][id]', image.id.toString())
            bodyFormData.append(
                'images[' + index + '][isFacebookShared]',
                image.isFacebookShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'images[' + index + '][isGoogleShared]',
                image.isGoogleShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'images[' + index + '][isInstagramShared]',
                image.isInstagramShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'images[' + index + '][isTwitterShared]',
                image.isTwitterShared ? 'true' : 'false',
            )
        })
    }

    const url = '/menuboards/:id'.replace(':id', parameters.id.toString())

    return axiosInstance.put(url, bodyFormData).then((result: AxiosResponse<Slate>) => {
        if (result.data) {
            return {
                id: result.data.establishment.id,
                slate: result.data,
                lastSlate: result.data,
            }
        }
        throw Error()
    })
}

export const shareSlate = (
    parameters: UpdateSlateRequest,
    setFacebookInProgress: (inProgress: boolean) => void = () => {},
    setGoogleInProgress: (inProgress: boolean) => void = () => {},
    setInstagramInProgress: (inProgress: boolean) => void = () => {},
    setTwitterInProgress: (inProgress: boolean) => void = () => {},
    onFacebookError: (error: AxiosError) => void = () => {},
    onGoogleError: (error: AxiosError) => void = () => {},
    onInstagramError: (error: AxiosError) => void = () => {},
    onTwitterError: (error: AxiosError) => void = () => {},
): Promise<CurrentSlateResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/menuboards/:id'.replace(':id', parameters.id.toString())

    const requests: Array<Promise<AxiosResponse<Slate> | { error: AxiosError }>> = []
    if (parameters.isGoogleShared !== undefined) {
        const bodyFormData = new FormData()
        if (parameters.isGoogleShared && parameters.googleAccessToken !== undefined) {
            bodyFormData.append('isGoogleShared', 'true')
            bodyFormData.append('googleAccessToken', parameters.googleAccessToken)
        } else if (!parameters.isGoogleShared) {
            bodyFormData.append('isGoogleShared', 'false')
        }
        requests.push(
            axiosInstance
                .put(url, bodyFormData, {
                    onUploadProgress: () => {
                        setGoogleInProgress(true)
                    },
                })
                .catch((error) => {
                    onGoogleError(error)
                    return { error }
                })
                .finally(() => {
                    setGoogleInProgress(false)
                }),
        )
    }

    if (parameters.isFacebookShared !== undefined) {
        const bodyFormData = new FormData()
        if (parameters.isFacebookShared && parameters.facebookAccessToken !== undefined) {
            bodyFormData.append('isFacebookShared', 'true')
            bodyFormData.append('facebookAccessToken', parameters.facebookAccessToken)
        } else if (!parameters.isFacebookShared) {
            bodyFormData.append('isFacebookShared', 'false')
        }
        requests.push(
            axiosInstance
                .put(url, bodyFormData, {
                    onUploadProgress: () => {
                        setFacebookInProgress(true)
                    },
                })
                .catch((error) => {
                    onFacebookError(error)
                    return { error }
                })
                .finally(() => {
                    setFacebookInProgress(false)
                }),
        )
    }

    if (parameters.isInstagramShared !== undefined) {
        const bodyFormData = new FormData()
        if (parameters.isInstagramShared) {
            bodyFormData.append('isInstagramShared', 'true')
        } else {
            bodyFormData.append('isInstagramShared', 'false')
        }
        requests.push(
            axiosInstance
                .put(url, bodyFormData, {
                    onUploadProgress: () => {
                        setInstagramInProgress(true)
                    },
                })
                .catch((error) => {
                    onInstagramError(error)
                    return { error }
                })
                .finally(() => {
                    setInstagramInProgress(false)
                }),
        )
    }

    if (parameters.isTwitterShared !== undefined) {
        const bodyFormData = new FormData()
        if (parameters.isTwitterShared) {
            bodyFormData.append('isTwitterShared', 'true')
        } else {
            bodyFormData.append('isTwitterShared', 'false')
        }
        requests.push(
            axiosInstance
                .put(url, bodyFormData, {
                    onUploadProgress: () => {
                        setTwitterInProgress(true)
                    },
                })
                .catch((error) => {
                    onTwitterError(error)
                    return { error }
                })
                .finally(() => {
                    setTwitterInProgress(false)
                }),
        )
    }

    return axios
        .all(requests)
        .then(
            axios.spread((...responses) => {
                const result = responses
                    .filter((response) => 'data' in response)
                    .map((response) => {
                        if ('data' in response) {
                            return response.data.establishment.id
                        }
                        throw Error()
                    })
                if (result.length > 0) {
                    return result[0]
                }
                throw Error()
            }),
        )
        .then((establishmentId) => {
            if (establishmentId) {
                return getCurrentSlate({ id: establishmentId })
            }
            return Promise.reject()
        })
}
