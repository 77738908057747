import { useEffect, useState } from 'react'
import { useGetMenuEntries } from './useGetMenuEntries'
import APIError from '../../resources/error'
import { useEstablishment } from './useEstablishment'

export const useEstablishmentMenuEntries = (establishmentId: number) => {
    const establishment = useEstablishment(establishmentId)

    const [isReady, setIsReady] = useState<boolean>(false)
    const [error, setError] = useState<APIError>()
    const { getMenuEntries, inProgress } = useGetMenuEntries()

    useEffect(() => {
        if (!inProgress && establishment !== undefined && error === undefined) {
            if (establishment.menuEntries === undefined) {
                getMenuEntries({
                    id: establishmentId,
                })
                    .then(() => {
                        setIsReady(true)
                    })
                    .catch((error) => {
                        setError(error)
                        setIsReady(true)
                    })
            } else {
                if (!isReady) {
                    setIsReady(true)
                }
            }
        }
    }, [establishment, establishmentId, inProgress, error, getMenuEntries, isReady])

    return {
        isReady,
        establishmentMenuEntries: establishment?.menuEntries ?? [],
        error,
    }
}
