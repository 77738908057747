import { useState } from 'react'
import { queries } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { useDispatch } from '../redux'
import { User } from '../../resources/auth/types'

export const useRefreshUser = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const refreshUser = (): Promise<User> => {
        setInProgress(true)
        return queries
            .getProfile()
            .then((response) => {
                dispatch(actions.setUser(response))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                dispatch(actions.signOut())
                throw generateError(error)
            })
    }

    return {
        refreshUser,
        inProgress,
    }
}
