import { useEffect, useState } from 'react'
import { ApplicationState } from '../../store'
import { Slate } from '../../resources/slate/types'
import { useGetCurrentSlate } from './useGetCurrentSlate'
import APIError from '../../resources/error'
import { useSelector } from '../redux'

export const useEstablishmentSlate = (establishmentId: number) => {
    const { establishments } = useSelector(({ suggpro: { establishment } }: ApplicationState) => ({
        establishments: establishment.establishments,
    }))

    const [error, setError] = useState<APIError>()
    const { getCurrentSlate, inProgress } = useGetCurrentSlate()

    let establishmentSlate: Slate | null = null
    let establishmentLastSlate: Slate | null = null
    let isAlreadyLoaded = false
    if (establishments !== undefined) {
        const establishment = establishments.find((establishment) => establishment.id === establishmentId)
        if (establishment !== undefined && establishment.slate !== undefined) {
            isAlreadyLoaded = true
            establishmentSlate = establishment.slate
        }
        if (establishment !== undefined && establishment.lastSlate !== undefined) {
            isAlreadyLoaded = true
            establishmentLastSlate = establishment.lastSlate
        }
    }
    const [isReady, setIsReady] = useState<boolean>(isAlreadyLoaded)

    useEffect(() => {
        if (!isReady && !inProgress && establishments !== undefined && error === undefined) {
            const establishment = establishments.find((establishment) => establishment.id === establishmentId)
            if (establishment !== undefined) {
                if (establishment.slate === undefined) {
                    getCurrentSlate({
                        id: establishmentId,
                    })
                        .then(() => {
                            setIsReady(true)
                        })
                        .catch((error) => {
                            setError(error)
                            setIsReady(true)
                        })
                } else {
                    if (!isReady) {
                        setIsReady(true)
                    }
                }
            }
        }
    }, [isReady, establishments, establishmentId, inProgress, error, getCurrentSlate])

    return {
        isReady,
        establishmentSlate,
        establishmentLastSlate,
        error,
    }
}
