import actions from '../../store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'
import { MenuEntriesResponse, UpdateMenuEntriesRequest } from '../../resources/establishment/types'
import { useDispatch } from '../redux'

export const useUpdateMenuEntries = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const updateMenuEntries = (payload: UpdateMenuEntriesRequest): Promise<MenuEntriesResponse> => {
        setInProgress(true)
        return mutations
            .updateMenuEntries(payload)
            .then((response) => {
                setInProgress(false)
                dispatch(actions.setMenuEntries(response))
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        updateMenuEntries,
        inProgress,
    }
}
