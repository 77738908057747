import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'
import { FacebookPagesRequest, FacebookPagesResponse } from '../../resources/restaurantOwner/types'

export const useGetFacebookPages = () => {
    const [inProgress, setInProgress] = useState(false)

    const getFacebookPages = (payload: FacebookPagesRequest): Promise<FacebookPagesResponse> => {
        setInProgress(true)
        return queries
            .getFacebookPages(payload)
            .then((response) => {
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        getFacebookPages,
        inProgress,
    }
}
