import { MessagingAuthor, MessagingConversation, PostMessage } from './types'
import { MessagingState, MessagingStatus } from '../../store/messaging/types'
import { Establishment } from '../establishment/types'

/**
 * Find the value in the list
 * @param list List of MessagingConversation where to find the value
 * @param conversationId Id to find in the list of MessagingConversation
 * @return boolean True if the value is in the list, False if value is not in the list
 */
export function inConversations(list: MessagingConversation[], conversationId: number) {
    return list.find((conv) => conv.id === conversationId) !== undefined
}

// <editor-fold desc="Update Status">
/**
 * If there are unread conversation in store
 * @param state Messaging state (actual or updated)
 * @return number -- Count of unread conversation
 */
export const hasUnreadConversationsInStore = (state: MessagingState): number => {
    const allConversation = allConversations(state)

    const unreadConv = allConversation?.filter((conv) => conv.unread)

    if (unreadConv) {
        return unreadConv.length
    } else {
        return 0
    }
}

/**
 * Update the Messaging Status
 * @param state Messaging State (actual or updated)
 * @return MessagingStatus -- Return updated status
 */
export const updateStatus = (state: MessagingState): MessagingStatus => {
    const unreadCount = hasUnreadConversationsInStore(state)
    const convCount = allConversations(state)?.length || 0
    return {
        unreadConversationCount: unreadCount,
        hasUnreadConversation: unreadCount > 0,
        conversationCount: convCount,
    }
}
//</editor-fold>

/**
 * Compare previous state and next state and count then number of removed conversations
 * @param prev_state Previous State
 * @param next_state Next State
 * @return number -- Count of removed conversation
 */
export const countRemovedConversations = (prev_state: MessagingState, next_state: MessagingState): number => {
    const prev_allConversations = allConversations(prev_state)
    const next_allConversations = allConversations(next_state)

    return (
        (prev_allConversations ? prev_allConversations.length : 0) -
        (next_allConversations ? next_allConversations.length : 0)
    )
}

/**
 * Concat private_message, post and review conversations to get one array of all conversations
 * @param state Messaging State
 * @return MessagingConversation[] | undefined -- Returning array of all conversations or undefined
 */
export const allConversations = (state: MessagingState): MessagingConversation[] | undefined => {
    const { convPrivateMessages, convPosts, convReviews } = state

    return convPrivateMessages?.concat(convPosts || []).concat(convReviews || [])
}

export const getAuthor = (
    postMessage: PostMessage,
    establishments: Establishment[] | undefined,
    messagingState: MessagingState,
): MessagingAuthor => {
    const { listMessages, listPostDetails } = messagingState
    const conversationId = postMessage.conversation
    const conversation = allConversations(messagingState)?.find((conv) => conv.id === conversationId)
    const establishment = establishments?.find((esta) => esta.id === postMessage.establishment)

    // Google review case
    if (
        conversation &&
        establishment &&
        conversation.typeConversation === 'review' &&
        conversation.typePlatform === 'google'
    ) {
        return fakeAuthor(establishment, conversation)
    }

    // Search in postDetails the author (post)
    const indexPostDetails = listPostDetails?.findIndex(
        (postDetail) => postDetail.conversation === conversationId,
    )
    if (listPostDetails && indexPostDetails !== undefined && indexPostDetails !== -1) {
        return listPostDetails[indexPostDetails].author
    }

    // Search in messages (private message)
    const indexListMessage = listMessages?.findIndex(
        (setMessage) => setMessage.conversation === conversationId,
    )
    if (establishment && indexListMessage !== undefined && indexListMessage !== -1 && listMessages) {
        const messageAuthor = listMessages[indexListMessage].messages.filter(
            (message) =>
                message.author.userId === establishment.facebookPageId ||
                message.author.userId === establishment.instagramPageId ||
                message.author.userId === establishment.twitterID ||
                message.author.userId === establishment.googleId,
        )
        return messageAuthor[0].author
    }

    if (conversation && establishment) {
        return fakeAuthor(establishment, conversation)
    }
    return {
        userId: '',
        img: null,
        link: '',
        name: null,
        username: null,
    }
}

const fakeAuthor = (establishment: Establishment, conversation: MessagingConversation) => {
    // Create fake MessagingAuthor
    let userId: string = ''
    switch (conversation.typePlatform) {
        case 'facebook':
            userId = establishment.facebookPageId || ''
            break
        case 'instagram':
            userId = establishment.instagramPageId || ''
            break
        case 'twitter':
            userId = establishment.twitterID || ''
            break
        case 'google':
            userId = establishment.googleId || ''
            break
    }
    return {
        userId: userId,
        img: establishment?.pictures[0].url || null,
        link: '',
        name: establishment?.name || null,
        username: null,
    }
}
