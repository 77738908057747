import { Reducer } from 'redux'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { AuthState, SignIn } from './types'

const initialState: AuthState = {
    signInInProgress: false,
}

const reducer: Reducer<AuthState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.signIn): {
            const { refresh, access } = action.payload as SignIn
            return {
                ...state,
                refresh,
                access,
            }
        }
        case getType(actions.setUser): {
            const user = action.payload
            return {
                ...state,
                user,
            }
        }
        case getType(actions.setSignInInProgressStart): {
            return {
                ...state,
                signInInProgress: true,
            }
        }
        case getType(actions.setSignInInProgressEnd): {
            return {
                ...state,
                signInInProgress: false,
            }
        }
        case getType(actions.signOut): {
            return {
                ...state,
                user: undefined,
                refresh: undefined,
            }
        }
        case getType(actions.verifyEmail): {
            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        hasEmailValidated: true,
                    },
                }
            }
            return state
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        case getType(actions.openingWebsocket): {
            const connectionId = action.payload
            return {
                ...state,
                websocketOpened: undefined,
                websocketOpening: connectionId,
            }
        }
        case getType(actions.openWebsocket): {
            const connectionId = action.payload
            return {
                ...state,
                websocketOpened: connectionId,
                websocketOpening: undefined,
            }
        }
        case getType(actions.closeWebsocket): {
            const connectionId = action.payload
            if (connectionId === state.websocketOpened || connectionId === state.websocketOpening) {
                return {
                    ...state,
                    websocketOpened: undefined,
                    websocketOpening: undefined,
                }
            } else {
                return state
            }
        }
        default:
            return state
    }
}

export { reducer as authReducer }
