import { EventModel } from '../../models'
import { SuggPro } from '../../client/suggPro'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { CreateEventRequest } from './types'

export const createEvent = (parameters: CreateEventRequest): Promise<EventModel> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    bodyFormData.append('establishment', parameters.establishment.toString())
    bodyFormData.append('startTime', moment(parameters.startTime).format())
    bodyFormData.append('endTime', moment(parameters.endTime).format())
    bodyFormData.append('title', parameters.title)
    if (parameters.pictures) {
        parameters.pictures.forEach((image, index) => {
            if (image.file) {
                bodyFormData.append('files[' + index + '][file]', image.file)
            }
            if (image.comment) {
                bodyFormData.append('files[' + index + '][comment]', image.comment)
            }
            bodyFormData.append(
                'files[' + index + '][isFacebookShared]',
                image.isFacebookShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isGoogleShared]',
                image.isGoogleShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isInstagramShared]',
                image.isInstagramShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isTwitterShared]',
                image.isTwitterShared ? 'true' : 'false',
            )
        })
    }
    if (parameters.comment !== undefined) {
        bodyFormData.append('comment', parameters.comment)
    }
    if (parameters.isFacebookShared) {
        bodyFormData.append('isFacebookShared', 'true')
    }
    if (parameters.isInstagramShared) {
        bodyFormData.append('isInstagramShared', 'true')
    }
    if (parameters.isTwitterShared) {
        bodyFormData.append('isTwitterShared', 'true')
    }
    if (parameters.isGoogleShared) {
        bodyFormData.append('isGoogleShared', 'true')
    }

    const url = '/event'

    return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<EventModel>) => {
        return result.data || undefined
    })
}
