import { User } from './types'
import { convertToEstablishment } from '../establishment/helpers'

export const convertToUser = (data: any): User => {
    const transformedEstablishments = []
    for (let i = 0; i < data.establishments.length; i++) {
        const establishment = data.establishments[i]
        transformedEstablishments.push(convertToEstablishment(establishment))
    }
    return {
        ...data,
        establishments: transformedEstablishments,
    }
}
