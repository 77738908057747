import { useDispatch } from '../redux'
import { useState } from 'react'
import { mutations } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { NewsModel } from '../../models'
import { CreateNewsRequest } from '../../resources/news/types'

export const useNewsCreate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const createNews = (parameters: CreateNewsRequest): Promise<NewsModel> => {
        setInProgress(true)
        return mutations
            .createNews(parameters)
            .then((response) => {
                dispatch(actions.addNews(response))
                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        createNews,
        inProgress,
    }
}
