import { useState } from 'react'
import { Establishment, EstablishmentRedeemCodeRequest } from '../../resources/establishment/types'
import { mutations } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { useDispatch } from '../redux'

export const useRedeemCode = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const redeemCode = (payload: EstablishmentRedeemCodeRequest): Promise<Establishment> => {
        setInProgress(true)
        return mutations
            .establishmentRedeemCode(payload)
            .then((response) => {
                dispatch(actions.setEstablishment(response))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        redeemCode,
        inProgress,
    }
}
