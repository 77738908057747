import actions from '../../store/actions'
import { ResendVerifyEmailRequest, VerifyEmailRequest } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'
import { useDispatch } from '../redux'

export const useEmailVerification = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)
    const [resendInProgress, setResendInProgress] = useState(false)

    const verifyEmail = (payload: VerifyEmailRequest): Promise<undefined> => {
        setInProgress(true)
        return mutations
            .verifyEmail(payload)
            .then(() => {
                setInProgress(false)
                dispatch(actions.verifyEmail())
                return undefined
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    const resendVerifyEmail = (payload: ResendVerifyEmailRequest): Promise<undefined> => {
        setResendInProgress(true)
        return mutations
            .resendVerifyEmail(payload)
            .then(() => {
                setResendInProgress(false)
                return undefined
            })
            .catch((error) => {
                setResendInProgress(false)
                throw generateError(error)
            })
    }

    return {
        verifyEmail,
        resendVerifyEmail,
        inProgress,
        resendInProgress,
    }
}
