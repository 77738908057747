import { call, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import { actions } from '../actions'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'
import { FacebookPagesResponse, GooglePagesResponse } from '../../resources/restaurantOwner/types'
import { AxiosError } from 'axios'

function* restaurantOwnerSaga() {
    yield takeLatest(getType(actions.getFacebookPages), getFacebookPages)
    yield takeLatest(getType(actions.getGooglePages), getGooglePages)
}

export function* getFacebookPages(action: ActionType<typeof actions.getFacebookPages>) {
    try {
        const facebookPagesResponse: FacebookPagesResponse = yield call(
            queries.getFacebookPages,
            action.payload,
        )
        return facebookPagesResponse
    } catch (error) {
        throw generateError(error as AxiosError | undefined)
    }
}

export function* getGooglePages(action: ActionType<typeof actions.getGooglePages>) {
    try {
        const googlePagesResponse: GooglePagesResponse = yield call(queries.getGooglePages, action.payload)
        return googlePagesResponse
    } catch (error) {
        throw generateError(error as AxiosError | undefined)
    }
}

export default restaurantOwnerSaga
