import { SuggProState } from '../../store'
import { useCallback, useEffect, useState } from 'react'
import APIError from '../../resources/error'
import { useGetSlateCategories } from './useGetSlateCategories'
import { useSelector } from '../redux'

export const useSlateCategories = () => {
    const { categories } = useSelector(({ suggpro: { slate } }: { suggpro: SuggProState }) => ({
        categories: slate.categories,
    }))

    const [error, setError] = useState<APIError>()
    const { getSlateCategories, inProgress } = useGetSlateCategories()

    const getSlateCategoriesInternal = useCallback(getSlateCategories, [])
    useEffect(() => {
        if (categories === undefined) {
            getSlateCategoriesInternal()
                .then(() => {})
                .catch((error) => {
                    setError(error)
                })
        }
    }, [categories, getSlateCategoriesInternal])

    return {
        inProgress,
        categories,
        error,
    }
}
