import actions from '../../store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'
import { CreateSlateRequest } from '../../resources/slate/types'
import { CreateSlateResponse } from '../../resources/establishment/types'
import { useDispatch } from '../redux'

export const useCreateSlate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const createSlate = (payload: CreateSlateRequest): Promise<CreateSlateResponse> => {
        setInProgress(true)
        return mutations
            .createSlate(payload)
            .then((response) => {
                setInProgress(false)
                dispatch(actions.setCurrentSlate(response))
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        createSlate,
        inProgress,
    }
}
