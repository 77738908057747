import {
    Establishment,
    EstablishmentType,
    MenuBoardProperty,
    NewsProperty,
    ReminderNotebook,
    Statistics,
} from '../../resources/establishment/types'
import { EstablishmentsGroupsModel, GroupFacebookModel } from '../../models'

export enum EstablishmentActionTypes {
    SET_MENU_ENTRIES = 'establishment/SET_MENU_ENTRIES',
    DELETE_MENU_ENTRY = 'establishment/DELETE_MENU_ENTRY',
    SET_CURRENT_SLATE = 'establishment/SET_CURRENT_SLATE',
    SET_ESTABLISHMENT = 'establishment/SET_ESTABLISHMENT',
    SET_ESTABLISHMENT_PICTURES = 'establishment/SET_ESTABLISHMENT_PICTURES',
    SET_ESTABLISHMENT_WEBSITE_SECTION = 'establishment/SET_ESTABLISHMENT_WEBSITE_SECTION',
    DELETE_ESTABLISHMENT_PICTURE = 'establishment/DELETE_ESTABLISHMENT_PICTURE',
    SET_ESTABLISHMENT_TYPES = 'establishment/SET_ESTABLISHMENT_TYPES',
    CONNECT_TO_FACEBOOK_PAGE = 'establishment/CONNECT_TO_FACEBOOK_PAGE',
    CONNECT_TO_GOOGLE_PAGE = 'establishment/CONNECT_TO_GOOGLE_PAGE',
    SET_REMINDER_NOTEBOOKS = 'establishment/SET_REMINDER_NOTEBOOKS',
    ADD_SPECIAL_CLOSING = 'establishment/ADD_SPECIAL_CLOSING',
    DELETE_SPECIAL_CLOSING = 'establishment/DELETE_SPECIAL_CLOSING',
    SET_STATISTICS = 'establishment/SET_STATISTICS',
    SET_ESTABLISHMENT_GROUPS = 'establishment/SET_ESTABLISHMENT_GROUPS',
}

export interface EstablishmentState {
    establishments?: Array<Establishment>
    establishmentTypes?: Array<EstablishmentType>
    statistics?: Array<Statistics<MenuBoardProperty>>
    newsStatistics?: Array<Statistics<NewsProperty>>
    establishmentsGroups?: EstablishmentsGroupsModel
}

export interface SetReminderNotebooksAction {
    establishmentId: number
    reminderNotebooks: Array<ReminderNotebook>
}

export interface SetEstablishmentGroupsAction {
    establishmentId: number
    groups: Array<GroupFacebookModel>
}
