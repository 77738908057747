import { createAction } from 'typesafe-actions'
import { RestaurantOwnerActionTypes } from './types'
import { prefix } from '../../config/config'
import { FacebookPagesRequest, GooglePagesRequest } from '../../resources/restaurantOwner/types'

const actionPrefix = '@@' + prefix + '/'

export const getFacebookPages = createAction(actionPrefix + RestaurantOwnerActionTypes.GET_FACEBOOK_PAGES)<
    FacebookPagesRequest
>()
export const getGooglePages = createAction(actionPrefix + RestaurantOwnerActionTypes.GET_GOOGLE_PAGES)<
    GooglePagesRequest
>()
