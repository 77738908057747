import { ActionType, getType } from 'typesafe-actions'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { actions as suggProActions } from '../actions'
import { Establishment } from '../../resources/establishment/types'
import { ApplicationState } from '../reducers'
import { GetMessagingConversation, MessagingConversation } from '../../resources/messages/types'
import { getMessagingConversation } from '../../resources/messages/queries'

function* messagingSaga() {
    yield takeLatest(getType(suggProActions.addMessagingMessage), loadMessagingConversation)
}

function* loadMessagingConversation(action: ActionType<typeof suggProActions.addMessagingMessage>) {
    const lastMessage = action.payload
    const establishments: Array<Establishment> | undefined = yield select(
        ({ suggpro: { establishment } }: ApplicationState) => establishment.establishments,
    )

    // Find the establishment concerned by the last message
    const establishment = establishments?.find((esta) => esta.id === lastMessage.establishment)
    if (establishment) {
        // Get if the lastMessage conversation is in the store
        const allConversations: Array<MessagingConversation> | undefined = yield select(
            ({ suggpro: { messaging } }: ApplicationState) =>
                messaging.convPrivateMessages
                    ?.concat(messaging.convPosts || [])
                    .concat(messaging.convReviews || []) || [],
        )
        const indexConversation = allConversations?.findIndex((conv) => conv.id === lastMessage.conversation)
        // If no conversations in store or if the lastMessage conversation is not found in store
        if (!allConversations || (allConversations && indexConversation === -1)) {
            // Call API and get conversation by type of lastMessage conversation typeConversation
            const objGetConversation: GetMessagingConversation = {
                establishment: establishment.id,
                typeConversation: lastMessage.typeConversation,
            }
            try {
                const response: Array<MessagingConversation> = yield call(
                    getMessagingConversation,
                    objGetConversation,
                )
                // Dispatch action to update the store the conversations
                yield put(
                    suggProActions.setMessagingConversation({
                        type: lastMessage.typeConversation,
                        conversations: response,
                    }),
                )
            } catch (error) {}
        }

        // Dispatch ready action
        yield put(suggProActions.messagingConversationReady(lastMessage))
    }
}

export default messagingSaga
