import { createAction } from 'typesafe-actions'
import { prefix } from '../../config/config'
import { NotificationPreferenceModel } from '../../models'
import { NotificationTokenRequest, User } from '../../resources/auth/types'
import { AuthActionTypes, SignIn } from './types'

const actionPrefix = '@@' + prefix + '/'

export const signIn = createAction(actionPrefix + AuthActionTypes.SIGN_IN)<SignIn>()
export const setSignInInProgressStart = createAction(
    actionPrefix + AuthActionTypes.SET_SIGN_IN_IN_PROGRESS_START,
)()
export const setSignInInProgressEnd = createAction(
    actionPrefix + AuthActionTypes.SET_SIGN_IN_IN_PROGRESS_END,
)()
export const setUser = createAction(actionPrefix + AuthActionTypes.SET_USER)<User>()
export const signOut = createAction(actionPrefix + AuthActionTypes.SIGN_OUT)()
export const verifyEmail = createAction(actionPrefix + AuthActionTypes.VERIFY_EMAIL)()
export const updateNotificationPreferences = createAction(
    actionPrefix + AuthActionTypes.UPDATE_NOTIFICATION_PREFERENCES,
)<NotificationPreferenceModel>()

export const sendToken = createAction(actionPrefix + AuthActionTypes.SEND_TOKEN)<NotificationTokenRequest>()

export const openWebsocket = createAction(actionPrefix + AuthActionTypes.OPEN_WEBSOCKET)<string>()
export const openingWebsocket = createAction(actionPrefix + AuthActionTypes.OPENING_WEBSOCKET)<string>()
export const closeWebsocket = createAction(actionPrefix + AuthActionTypes.CLOSE_WEBSOCKET)<string>()
