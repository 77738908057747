import { createAction } from 'typesafe-actions'
import { EstablishmentActionTypes, SetEstablishmentGroupsAction, SetReminderNotebooksAction } from './types'
import { prefix } from '../../config/config'
import {
    ConnectToFacebookPageRequest,
    ConnectToGooglePageRequest,
    CurrentSlateResponse,
    DeleteEstablishmentPictureRequest,
    DeleteMenuEntryResponse,
    DeleteSpecialClosingRequest,
    Establishment,
    EstablishmentPicturesResponse,
    EstablishmentType,
    MenuEntriesResponse,
    SpecialClosing,
    StatisticsResponse,
    UpdateEstablishmentWebsiteSectionResponse,
} from '../../resources/establishment/types'

const actionPrefix = '@@' + prefix + '/'

export const setMenuEntries = createAction(actionPrefix + EstablishmentActionTypes.SET_MENU_ENTRIES)<
    MenuEntriesResponse
>()
export const deleteMenuEntry = createAction(actionPrefix + EstablishmentActionTypes.DELETE_MENU_ENTRY)<
    DeleteMenuEntryResponse
>()

export const setCurrentSlate = createAction(actionPrefix + EstablishmentActionTypes.SET_CURRENT_SLATE)<
    CurrentSlateResponse
>()
export const setEstablishment = createAction(actionPrefix + EstablishmentActionTypes.SET_ESTABLISHMENT)<
    Establishment
>()
export const setEstablishmentTypes = createAction(
    actionPrefix + EstablishmentActionTypes.SET_ESTABLISHMENT_TYPES,
)<Array<EstablishmentType>>()

export const connectToFacebookPage = createAction(
    actionPrefix + EstablishmentActionTypes.CONNECT_TO_FACEBOOK_PAGE,
)<ConnectToFacebookPageRequest>()
export const connectToGooglePage = createAction(
    actionPrefix + EstablishmentActionTypes.CONNECT_TO_GOOGLE_PAGE,
)<ConnectToGooglePageRequest>()

export const setEstablishmentPictures = createAction(
    actionPrefix + EstablishmentActionTypes.SET_ESTABLISHMENT_PICTURES,
)<EstablishmentPicturesResponse>()

export const setEstablishmentWebsiteSection = createAction(
    actionPrefix + EstablishmentActionTypes.SET_ESTABLISHMENT_WEBSITE_SECTION,
)<UpdateEstablishmentWebsiteSectionResponse>()

export const deleteEstablishmentPicture = createAction(
    actionPrefix + EstablishmentActionTypes.DELETE_ESTABLISHMENT_PICTURE,
)<DeleteEstablishmentPictureRequest>()

export const setReminderNotebooks = createAction(
    actionPrefix + EstablishmentActionTypes.SET_REMINDER_NOTEBOOKS,
)<SetReminderNotebooksAction>()

export const addSpecialClosing = createAction(actionPrefix + EstablishmentActionTypes.ADD_SPECIAL_CLOSING)<
    SpecialClosing
>()
export const deleteSpecialClosing = createAction(
    actionPrefix + EstablishmentActionTypes.DELETE_SPECIAL_CLOSING,
)<DeleteSpecialClosingRequest>()

export const setStatistics = createAction(actionPrefix + EstablishmentActionTypes.SET_STATISTICS)<
    StatisticsResponse | undefined
>()

export const setEstablishmentGroups = createAction(
    actionPrefix + EstablishmentActionTypes.SET_ESTABLISHMENT_GROUPS,
)<SetEstablishmentGroupsAction>()
