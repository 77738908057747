import { useState } from 'react'
import { GetReminderNotebooksRequest, ReminderNotebook } from '../../resources/establishment/types'
import { queries } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { useDispatch } from '../redux'

export const useGetReminderNotebooks = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const getReminderNotebooks = (payload: GetReminderNotebooksRequest): Promise<Array<ReminderNotebook>> => {
        setInProgress(true)
        return queries
            .getReminderNotebooks(payload)
            .then((response) => {
                dispatch(
                    actions.setReminderNotebooks({
                        establishmentId: payload.id,
                        reminderNotebooks: response,
                    }),
                )
                setInProgress(false)
                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
    }

    return {
        getReminderNotebooks,
        inProgress,
    }
}
