import { AxiosResponse } from 'axios'
import moment from 'moment'
import { SuggPro } from '../../client/suggPro'
import {
    DeleteMessagingConversation,
    DeleteMessagingConversationMessage,
    MessagingConversation,
    PatchMessagingConversation,
    PostMessage,
} from './types'

export const updateMessagingConversationLastView = (
    parameters: PatchMessagingConversation,
): Promise<MessagingConversation> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/messages/:establishment/conversation/:conversation'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':conversation', parameters.id.toString())

    return axiosInstance.patch(url).then((result: AxiosResponse<MessagingConversation>) => {
        if (result.data) {
            // Convert date to real Date
            result.data.createdAt = moment(result.data.createdAt).toDate()
            result.data.updatedAt = moment(result.data.updatedAt).toDate()
            if (result.data.lastViewAt) {
                result.data.lastViewAt = moment(result.data.lastViewAt).toDate()
            }

            return result.data
        }
        throw Error()
    })
}

export const sendMessage = (parameters: PostMessage): Promise<AxiosResponse<string>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/messages/:establishment/message'.replace(
        ':establishment',
        parameters.establishment.toString(),
    )

    const bodyFormData = new FormData()
    bodyFormData.append('conversation', parameters.conversation.toString())
    bodyFormData.append('messageText', parameters.text)
    if (parameters.parentId !== null) {
        bodyFormData.append('parentId', parameters.parentId)
    } else {
        bodyFormData.append('parentId', '')
    }

    return axiosInstance.post(url, bodyFormData)
}

export const deleteMessagingConversation = (
    parameters: DeleteMessagingConversation,
): Promise<MessagingConversation> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    let url = '/messages/:establishment/conversation/moderate/:conversation'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':conversation', parameters.id.toString())

    if (parameters.block) {
        url += '?block=1'
    }

    return axiosInstance.delete(url).then((result: AxiosResponse<MessagingConversation>) => {
        if (result.data) {
            // Convert date to real Date
            result.data.createdAt = moment(result.data.createdAt).toDate()
            result.data.updatedAt = moment(result.data.updatedAt).toDate()
            if (result.data.lastViewAt) {
                result.data.lastViewAt = moment(result.data.lastViewAt).toDate()
            }

            return result.data
        }
        throw Error()
    })
}

export const deleteMessagingConversationMessage = (
    parameters: DeleteMessagingConversationMessage,
): Promise<void> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    let url = '/messages/:establishment/message/moderate/:conversation/:messageId'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':conversation', parameters.id.toString())
        .replace(':messageId', parameters.messageId)

    if (parameters.block) {
        url += '?block=1'
    }

    return axiosInstance.delete(url)
}

export const hideMessagingConversationMessage = (
    parameters: DeleteMessagingConversationMessage,
): Promise<void> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    let url = '/messages/:establishment/message/hide/:conversation/:messageId'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':conversation', parameters.id.toString())
        .replace(':messageId', parameters.messageId)

    return axiosInstance.delete(url)
}
