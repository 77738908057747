import { AxiosResponse } from 'axios'
import { User } from './types'
import { SuggPro } from '../../client/suggPro'
import { convertToUser } from './helpers'

export const getProfile = (): Promise<User> => {
    const axiosInstance = SuggPro.getAxiosInstance()
    return axiosInstance.get('/restaurant_owner/profile').then((result: AxiosResponse<User>) => {
        return convertToUser(result.data)
    })
}
