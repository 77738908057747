import { Store } from 'redux'
import { ClientOptions } from './options'
import { StorageInterface } from './storage'
import axios, { AxiosInstance } from 'axios'

export class SuggPro {
    static instance: SuggPro
    options: ClientOptions
    axiosInstance: AxiosInstance

    private constructor(options: ClientOptions) {
        this.options = options
        this.axiosInstance = SuggPro.createClient(
            options.apiEndpoint,
            options.versionName,
            options.versionNumber,
        )
    }

    static getInstance(options: ClientOptions): SuggPro {
        if (!this.instance) {
            this.instance = new SuggPro(options)
        }
        return this.instance
    }

    static getAxiosInstance(): AxiosInstance {
        if (!this.instance) {
            throw Error()
        }
        return this.instance.axiosInstance
    }

    static getStore(): Store {
        if (!this.instance) {
            throw Error()
        }
        return this.instance.options.store
    }

    static getSessionStorage(): StorageInterface {
        if (!this.instance) {
            throw Error()
        }
        return this.instance.options.sessionStorage
    }

    static getPersistentStorage(): StorageInterface {
        if (!this.instance) {
            throw Error()
        }
        return this.instance.options.persistentStorage
    }

    static getSha512(): (value: string) => Promise<string> {
        if (!this.instance) {
            throw Error()
        }
        return this.instance.options.sha512
    }

    private static createClient(
        apiEndpoint: string,
        versionName: string,
        versionNumber: string,
    ): AxiosInstance {
        return axios.create({
            baseURL: apiEndpoint,
            responseType: 'json',
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
                'app': 'sugg_pro',
                'version-name': versionName,
                'version-number': versionNumber,
            },
        })
    }
}
