import { useState } from 'react'
import { generateError } from '../../helpers/errors'
import { mutations } from '../../resources'
import { DeleteMessagingConversationMessage } from '../../resources/messages/types'
import { hideMessagingMessage } from '../../store/messaging/actions'
import { useDispatch } from '../redux'

export const useHideConversationMessage = () => {
    const [inProgress, setInProgress] = useState(false)
    const dispatch = useDispatch()

    const hideConversationMessage = (payload: DeleteMessagingConversationMessage): Promise<void> => {
        setInProgress(true)
        return mutations
            .hideMessagingConversationMessage(payload)
            .then(() => {
                dispatch(hideMessagingMessage({ conversation: payload.id, messageId: payload.messageId }))
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        hideConversationMessage,
        inProgress,
    }
}
