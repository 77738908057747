import { SuggPro } from '../../client/suggPro'
import { AxiosResponse } from 'axios'
import { GetPlansResponse, Plan } from './types'

export const getPlans = (): Promise<Array<Plan>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/stripe/plans'

    return axiosInstance.get(url).then((result: AxiosResponse<GetPlansResponse>) => {
        if (result.data) {
            const response: Array<Plan> = []
            if (result.data.monthly) {
                const data = result.data.monthly
                data.code = 'month'
                response.push(data)
            }
            if (result.data.yearly) {
                const data = result.data.yearly
                data.code = 'year'
                response.push(data)
            }
            if (result.data.monthly_booster) {
                const data = result.data.monthly_booster
                data.code = 'month_booster'
                response.push(data)
            }
            if (result.data.monthly_booster_no_trial) {
                const data = result.data.monthly_booster_no_trial
                data.code = 'monthly_booster_no_trial'
                response.push(data)
            }
            if (result.data.yearly_booster_no_trial) {
                const data = result.data.yearly_booster_no_trial
                data.code = 'yearly_booster_no_trial'
                response.push(data)
            }
            if (result.data.yearly_booster) {
                const data = result.data.yearly_booster
                data.code = 'year_booster'
                response.push(data)
            }
            if (result.data.yearly_booster_fht) {
                const data = result.data.yearly_booster_fht
                data.code = 'year_booster_fht'
                response.push(data)
            }
            if (result.data.yearly_booster_poitiers) {
                const data = result.data.yearly_booster_poitiers
                data.code = 'year_booster_poitiers'
                response.push(data)
            }
            if (result.data.yearly_booster_spe) {
                const data = result.data.yearly_booster_spe
                data.code = 'year_booster_spe'
                response.push(data)
            }
            return response
        }
        throw Error()
    })
}

export const getCustomerPortal = (): Promise<string> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/stripe/customer/portal'

    return axiosInstance.get(url).then((result: AxiosResponse<string>) => {
        if (result.data) {
            return result.data
        }
        throw Error()
    })
}
