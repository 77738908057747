import { all, call } from 'redux-saga/effects'
import establishmentSaga, * as establishmentSagas from '../store/establishment/sagas'
import restaurantOwnerSaga, * as restaurantOwnerSagas from '../store/restaurantOwner/sagas'
import slateSaga, * as slateSagas from '../store/slate/sagas'
import authSaga, * as authSagas from './auth/sagas'
import sessionSaga, * as sessionSagas from './session/sagas'
import messagingSaga, * as messagingSagas from './messaging/sagas'

export function* suggProSaga() {
    yield all([
        call(authSaga),
        call(sessionSaga),
        call(establishmentSaga),
        call(restaurantOwnerSaga),
        call(slateSaga),
        call(messagingSaga),
    ])
}

export const suggProSagas = {
    ...authSagas,
    ...sessionSagas,
    ...establishmentSagas,
    ...restaurantOwnerSagas,
    ...slateSagas,
    ...messagingSagas,
}

export default suggProSaga
