import {
    MessagingConversation,
    GetMessagingConversation,
    PostDetails,
    GetMessagingMessage,
    MessagingMessage,
    FetchContent,
} from './types'
import { SuggPro } from '../../client/suggPro'
import { AxiosResponse } from 'axios'
import moment from 'moment'

export const getMessagingConversation = (
    parameters: GetMessagingConversation,
): Promise<Array<MessagingConversation>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/messages/:establishment/thread/:type_conversation'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':type_conversation', parameters.typeConversation)

    return axiosInstance.get(url).then((result: AxiosResponse<Array<MessagingConversation>>) => {
        if (result.data) {
            // Convert date in real Date
            result.data.forEach((value) => {
                value.createdAt = moment(value.createdAt).toDate()
                value.updatedAt = moment(value.updatedAt).toDate()
                if (value.lastViewAt) {
                    value.lastViewAt = moment(value.lastViewAt).toDate()
                }
            })

            return result.data
        }
        throw Error()
    })
}

export const getMessagingMessage = (parameters: GetMessagingMessage): Promise<Array<MessagingMessage>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/messages/:establishment/conversation/:conversation/messages'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':conversation', parameters.conversation.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<Array<MessagingMessage>>) => {
        if (result.data) {
            // Convert date in real Date
            result.data.forEach((value) => {
                value.createdAt = moment(value.createdAt).toDate()
            })
            return result.data
        }
        throw Error()
    })
}

export const getPostDetails = (parameters: MessagingConversation): Promise<PostDetails> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/messages/:establishment/conversation/:conversation_id/post_details'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':conversation_id', parameters.id.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<PostDetails>) => {
        if (result.data) {
            // Convert date in real Date
            result.data.createdAt = moment(result.data.createdAt).toDate()

            return result.data
        }
        throw Error()
    })
}

export const getPostContent = (parameters: MessagingConversation): Promise<FetchContent> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/messages/:establishment/conversation/:conversation/content'
        .replace(':establishment', parameters.establishment.toString())
        .replace(':conversation', parameters.id.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<FetchContent>) => {
        if (result.data) {
            // Convert date in real Date
            if (result.data.postDetails) {
                result.data.postDetails.createdAt = moment(result.data.postDetails?.createdAt).toDate()
            }
            result.data.messages.forEach((value) => {
                value.createdAt = moment(value.createdAt).toDate()
            })

            return result.data
        }
        throw Error()
    })
}
