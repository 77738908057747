import { NewsModel } from '../../models'
import { SuggPro } from '../../client/suggPro'
import axios, { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import { CreateNewsRequest, ShareNewsRequest } from './types'
import { Slate } from '../slate/types'
import { getNews } from './queries'

export const createNews = (parameters: CreateNewsRequest): Promise<NewsModel> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    bodyFormData.append('establishment', parameters.establishment.toString())
    if (parameters.pictures) {
        parameters.pictures.forEach((image, index) => {
            if (image.file) {
                bodyFormData.append('files[' + index + '][file]', image.file)
            }
            if (image.comment) {
                bodyFormData.append('files[' + index + '][comment]', image.comment)
            }
            bodyFormData.append(
                'files[' + index + '][isFacebookShared]',
                image.isFacebookShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isGoogleShared]',
                image.isGoogleShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isInstagramShared]',
                image.isInstagramShared ? 'true' : 'false',
            )
            bodyFormData.append(
                'files[' + index + '][isTwitterShared]',
                image.isTwitterShared ? 'true' : 'false',
            )
        })
    }
    bodyFormData.append('endTime', moment(parameters.endTime).format())
    if (parameters.comment !== undefined) {
        bodyFormData.append('comment', parameters.comment)
    }
    if (parameters.isFacebookShared) {
        bodyFormData.append('isFacebookShared', 'true')
    }
    if (parameters.isInstagramShared) {
        bodyFormData.append('isInstagramShared', 'true')
    }
    if (parameters.isTwitterShared) {
        bodyFormData.append('isTwitterShared', 'true')
    }
    if (parameters.isGoogleShared) {
        bodyFormData.append('isGoogleShared', 'true')
    }

    const url = '/news'

    return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<NewsModel>) => {
        return result.data || undefined
    })
}

export const deleteNews = (id: number): Promise<void> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/news/:id'.replace(':id', id.toString())

    return axiosInstance.delete(url)
}

export const shareNews = (
    parameters: ShareNewsRequest,
    setFacebookInProgress: (inProgress: boolean) => void = () => {},
    setGoogleInProgress: (inProgress: boolean) => void = () => {},
    setInstagramInProgress: (inProgress: boolean) => void = () => {},
    setTwitterInProgress: (inProgress: boolean) => void = () => {},
    onFacebookError: (error: AxiosError) => void = () => {},
    onGoogleError: (error: AxiosError) => void = () => {},
    onInstagramError: (error: AxiosError) => void = () => {},
    onTwitterError: (error: AxiosError) => void = () => {},
): Promise<NewsModel> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/news/:id/share'.replace(':id', parameters.id.toString())

    const requests: Array<Promise<AxiosResponse<Slate> | { error: AxiosError }>> = []

    /* Facebook Share */
    if (parameters.isFacebookShared !== undefined) {
        const bodyFormData = { isFacebookShared: parameters.isFacebookShared.toString() }

        requests.push(
            axiosInstance
                .post(url, bodyFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    onUploadProgress: () => {
                        setFacebookInProgress(true)
                    },
                })
                .catch((error) => {
                    onFacebookError(error)
                    return { error }
                })
                .finally(() => {
                    setFacebookInProgress(false)
                }),
        )
    }

    /* Instagram Share */
    if (parameters.isInstagramShared !== undefined) {
        const bodyFormData = { isInstagramShared: parameters.isInstagramShared.toString() }

        requests.push(
            axiosInstance
                .post(url, bodyFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    onUploadProgress: () => {
                        setInstagramInProgress(true)
                    },
                })
                .catch((error) => {
                    onInstagramError(error)
                    return { error }
                })
                .finally(() => {
                    setInstagramInProgress(false)
                }),
        )
    }

    /* Twitter Share */
    if (parameters.isTwitterShared !== undefined) {
        const bodyFormData = { isTwitterShared: parameters.isTwitterShared.toString() }

        requests.push(
            axiosInstance
                .post(url, bodyFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    onUploadProgress: () => {
                        setTwitterInProgress(true)
                    },
                })
                .catch((error) => {
                    onTwitterError(error)
                    return { error }
                })
                .finally(() => {
                    setTwitterInProgress(false)
                }),
        )
    }

    /* Google Share */
    if (parameters.isGoogleShared !== undefined) {
        const bodyFormData = { isGoogleShared: parameters.isGoogleShared.toString() }

        requests.push(
            axiosInstance
                .post(url, bodyFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    onUploadProgress: () => {
                        setGoogleInProgress(true)
                    },
                })
                .catch((error) => {
                    onGoogleError(error)
                    return { error }
                })
                .finally(() => {
                    setGoogleInProgress(false)
                }),
        )
    }

    return axios
        .all(requests)
        .then(
            axios.spread((...responses) => {
                const result = responses
                    .filter((response) => 'data' in response)
                    .map((response) => {
                        if ('data' in response) {
                            return response.data.id
                        }
                        throw Error()
                    })
                if (result.length > 0) {
                    return result[0]
                }
                throw Error()
            }),
        )
        .then((newsId) => {
            if (newsId) {
                return getNews(newsId)
            }
            return Promise.reject()
        })
}

export const updateNews = (payload: ShareNewsRequest): Promise<NewsModel> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/news/:id/share'.replace(':id', payload.id.toString())

    return axiosInstance
        .post(url, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<NewsModel>) => {
            return result.data
        })
}
