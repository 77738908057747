import { useState } from 'react'
import { Establishment, UpdateEstablishmentRequest } from '../../resources/establishment/types'
import { mutations } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { useDispatch, useSelector } from '../redux'
import { ApplicationState } from '../../store'

export const useEstablishmentUpdate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)
    const { establishments } = useSelector(({ suggpro: { establishment } }: ApplicationState) => ({
        establishments: establishment.establishments,
    }))

    const updateEstablishment = (payload: UpdateEstablishmentRequest): Promise<Establishment> => {
        setInProgress(true)
        return mutations
            .updateEstablishment(payload)
            .then((response) => {
                const prevState = establishments?.find((esta) => esta.id === payload.id)
                if (prevState !== undefined) {
                    dispatch(
                        actions.deleteMessagingConversations({
                            prev_state: prevState,
                            next_state: response,
                        }),
                    )
                }
                dispatch(actions.setEstablishment(response))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        updateEstablishment,
        inProgress,
    }
}
