import { CheckEmailRequest } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'

export const useCheckEmail = () => {
    const [inProgress, setInProgress] = useState(false)

    const checkEmail = (payload: CheckEmailRequest): Promise<boolean> => {
        setInProgress(true)
        return mutations
            .checkEmail(payload)
            .then(() => {
                setInProgress(false)
                return true
            })
            .catch((error) => {
                setInProgress(false)
                if (error.response?.status === 400) {
                    return false
                }
                throw generateError(error)
            })
    }

    return {
        checkEmail,
        inProgress,
    }
}
