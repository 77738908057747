import { SuggPro } from '../../client/suggPro'
import { AxiosResponse } from 'axios'
import { NewsModel } from '../../models'

export const getNewsList = (): Promise<Array<NewsModel>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/news'

    return axiosInstance.get(url).then((result: AxiosResponse<Array<NewsModel>>) => {
        return result.data || []
    })
}

export const getNews = (id: number): Promise<NewsModel> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/news/:id'.replace(':id', id.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<NewsModel>) => {
        return result.data || undefined
    })
}
