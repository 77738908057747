import { SuggPro } from './suggPro'
import axios from 'axios'
import { refreshToken as refreshTokenAction } from '../resources/auth/mutations'
import { TokenResponse } from '../resources/auth/types'
import { Mutex } from 'async-mutex'

let currentRequestInterceptor: number | undefined = undefined
let currentResponseInterceptor: number | undefined = undefined
const mutex = new Mutex()
export const setBearerToken = (accessToken: string, refreshToken: string) => {
    const axiosInstance = SuggPro.getAxiosInstance()
    if (currentRequestInterceptor !== undefined) {
        axiosInstance.interceptors.request.eject(currentRequestInterceptor)
    }
    if (currentResponseInterceptor !== undefined) {
        axiosInstance.interceptors.response.eject(currentResponseInterceptor)
    }

    return new Promise<TokenResponse>((resolve, reject) => {
        currentRequestInterceptor = axiosInstance.interceptors.request.use(
            (config) => {
                if (accessToken) {
                    config.headers['Authorization'] = 'Bearer ' + accessToken
                }
                return config
            },
            (error) => {
                return Promise.reject(error)
            },
        )
        currentResponseInterceptor = axiosInstance.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                const originalRequest = error.config
                if (mutex.isLocked()) {
                    originalRequest._refreshing = true
                }
                if (
                    error.response &&
                    error.response.status === 401 &&
                    error.response.data &&
                    error.response.data.detail === 'Authentication credentials were not provided.' &&
                    !originalRequest._retry
                ) {
                    return mutex.runExclusive(() => {
                        originalRequest._retry = true
                        if (originalRequest._refreshing) {
                            return axiosInstance(originalRequest).then((response) => {
                                return response
                            })
                        }
                        return refreshTokenAction({
                            refresh: refreshToken,
                        })
                            .catch((error) => {
                                reject(error)
                                return Promise.reject(error)
                            })
                            .then((token) => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token.access
                                return axios(originalRequest)
                                    .then((response) => {
                                        return response
                                    })
                                    .catch((error) => {
                                        return Promise.reject(error)
                                    })
                                    .finally(() => {
                                        resolve(token)
                                    })
                            })
                    })
                }
                return Promise.reject(error)
            },
        )
    })
}
