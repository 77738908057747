import { SuggPro } from '../../client/suggPro'
import { AxiosResponse } from 'axios'
import { SlateCategory } from './types'

export const getSlateCategories = (): Promise<Array<SlateCategory>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/menuboards/categories'

    return axiosInstance.get(url).then((result: AxiosResponse<Array<SlateCategory>>) => {
        if (result.data) {
            return result.data
        }
        throw Error()
    })
}
