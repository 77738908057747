import { useState } from 'react'
import { DeleteEstablishmentPictureRequest } from '../../resources/establishment/types'
import { mutations } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { useDispatch } from '../redux'

export const useEstablishmentPicturesDelete = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const deleteEstablishmentPicture = (payload: DeleteEstablishmentPictureRequest): Promise<void> => {
        setInProgress(true)
        return mutations
            .deleteEstablishmentPictures(payload)
            .then(() => {
                dispatch(actions.deleteEstablishmentPicture(payload))
                setInProgress(false)
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        deleteEstablishmentPicture,
        inProgress,
    }
}
