import { AxiosError } from 'axios'

export const UnexpectedError = 'UnexpectedError'

export class APIError extends Error {
    type: string
    data: any
    status?: number
    axiosError?: AxiosError

    constructor(
        type: string = UnexpectedError,
        message?: string,
        data?: any,
        status?: number,
        axiosError?: AxiosError,
    ) {
        super(message ? message : type)
        this.name = type
        this.type = type
        this.data = data
        this.status = status
        this.axiosError = axiosError
    }
}

export default APIError

export class FacebookTokenExpired extends APIError {
    constructor(data: any) {
        super('FacebookTokenExpired', data.detail, data)
    }
}

export class FacebookWrongPermissions extends APIError {
    constructor(data: any) {
        super('FacebookWrongPermissions', data.detail, data)
    }
}

export class FacebookTokenRequired extends APIError {
    constructor(data: any) {
        super('FacebookTokenRequired', data.detail, data)
    }
}

export class FacebookSessionExpired extends APIError {
    constructor(data: any) {
        super('FacebookSessionExpired', data.detail, data)
    }
}

export class FacebookPageNotLinked extends APIError {
    constructor(data: any) {
        super('FacebookPageNotLinked', data.detail, data)
    }
}

export class GoogleTokenExpired extends APIError {
    constructor(data: any) {
        super('GoogleTokenExpired', data.detail, data)
    }
}

export class GoogleTokenRequired extends APIError {
    constructor(data: any) {
        super('GoogleTokenRequired', data.detail, data)
    }
}

export class GooglePageNotLinked extends APIError {
    constructor(data: any) {
        super('GooglePageNotLinked', data.detail, data)
    }
}

export class GoogleOfflineCodeExpired extends APIError {
    constructor(data: any) {
        super('GoogleOfflineCodeExpired', data.detail, data)
    }
}

export class AccountExist extends APIError {
    constructor(data: any) {
        super('AccountExist', data.detail, data)
    }
}

export class FacebookAccountExist extends APIError {
    constructor(data: any) {
        super('FacebookAccountExist', data.detail, data)
    }
}

export class GoogleAccountExist extends APIError {
    constructor(data: any) {
        super('GoogleAccountExist', data.detail, data)
    }
}

export class SuggProAccountExist extends APIError {
    constructor(data: any) {
        super('SuggProAccountExist', data.detail, data)
    }
}

export class Sugg1144AccountExist extends APIError {
    constructor(data: any) {
        super('Sugg1144AccountExist', data.detail, data)
    }
}

export class SuggProGoogleAccountExist extends APIError {
    constructor(data: any) {
        super('SuggProGoogleAccountExist', data.detail, data)
    }
}

export class SuggProFacebookAccountExist extends APIError {
    constructor(data: any) {
        super('SuggProFacebookAccountExist', data.detail, data)
    }
}

export class Sugg1144GoogleAccountExist extends APIError {
    constructor(data: any) {
        super('Sugg1144GoogleAccountExist', data.detail, data)
    }
}

export class Sugg1144FacebookAccountExist extends APIError {
    constructor(data: any) {
        super('Sugg1144FacebookAccountExist', data.detail, data)
    }
}

export class WrongCode extends APIError {
    constructor(data: any) {
        super('WrongCode', data.detail, data)
    }
}

export class CodeAlreadyRedeem extends APIError {
    constructor(data: any) {
        super('CodeAlreadyRedeem', data.detail, data)
    }
}

export class TwitterExpired extends APIError {
    constructor(data: any) {
        super('TwitterExpired', data.detail, data)
    }
}

export class TwitterTemporary extends APIError {
    constructor(data: any) {
        super('TwitterTemporary', data.detail, data)
    }
}

/* Messaging Errors */
export class MessagingMessageNotSend extends APIError {
    constructor(data: any) {
        super('MessagingMessageNotSend', data.detail, data)
    }
}

export class MessagingConversationNotFound extends APIError {
    constructor(data: any) {
        super('MessagingConversationNotFound', data.detail, data)
    }
}
