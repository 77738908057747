import {
    CurrentSlateRequest,
    CurrentSlateResponse,
    CurrentSlatesResponse,
    Establishment,
    EstablishmentPicture,
    EstablishmentPicturesRequest,
    EstablishmentPicturesResponse,
    EstablishmentType,
    GetEstablishmentRequest,
    GetReminderNotebooksRequest,
    GetSpecialClosing,
    GetTypesRequest,
    MenuEntriesRequest,
    MenuEntriesResponse,
    MenuEntry,
    ReminderNotebook,
    SpecialClosing,
    StatisticsRequest,
    StatisticsResponse,
} from './types'
import { SuggPro } from '../../client/suggPro'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { GroupFacebookModel } from '../../models'

export const getMenuEntries = (parameters: MenuEntriesRequest): Promise<MenuEntriesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/menu_entries'.replace(':id', parameters.id.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<Array<MenuEntry>>) => {
        if (result.data) {
            return {
                id: parameters.id,
                menuEntries: result.data ?? [],
            }
        }
        throw Error()
    })
}

export const getCurrentSlate = (parameters: CurrentSlateRequest): Promise<CurrentSlateResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/current_menuboards'.replace(':id', parameters.id.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<CurrentSlatesResponse | null>) => {
        return {
            id: parameters.id,
            slate: result.data?.current ?? null,
            lastSlate: result.data?.last ?? null,
        }
    })
}

export const getTypes = (_: GetTypesRequest): Promise<Array<EstablishmentType>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/types'

    return axiosInstance.get(url).then((result: AxiosResponse<Array<EstablishmentType>>) => {
        return result.data
    })
}

export const getEstablishmentPictures = (
    parameters: EstablishmentPicturesRequest,
): Promise<EstablishmentPicturesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/pictures'.replace(':id', parameters.id.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<Array<EstablishmentPicture>>) => {
        if (result.data) {
            return {
                id: parameters.id,
                pictures: result.data,
            }
        }
        throw Error()
    })
}

export const getEstablishment = (parameters: GetEstablishmentRequest): Promise<Establishment> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id'.replace(':id', parameters.id.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<Establishment>) => {
        if (result.data) {
            return result.data
        }
        throw Error()
    })
}

export const getReminderNotebooks = (
    parameters: GetReminderNotebooksRequest,
): Promise<Array<ReminderNotebook>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    let url = '/establishment/:id/reminder-notebooks'.replace(':id', parameters.id.toString())
    if (parameters.date) {
        url += '/' + parameters.date
    }

    return axiosInstance.get(url).then((result: AxiosResponse<Array<ReminderNotebook>>) => {
        if (result.data) {
            return result.data
        }
        throw Error()
    })
}

export const getSpecialClosing = (parameters: GetSpecialClosing): Promise<Array<SpecialClosing>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/establishment/:id/special_closing'.replace(':id', parameters.establishment.toString())

    return axiosInstance.get(url).then((result: AxiosResponse<Array<SpecialClosing>>) => {
        if (result.data) {
            return result.data
        }
        throw Error()
    })
}

export const getStatistics = (parameters: StatisticsRequest): Promise<StatisticsResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    let url = '/establishment/:id/statistics'.replace(':id', parameters.id.toString())
    if (parameters.startDate && parameters.endDate) {
        url +=
            '?startDate=' +
            moment(parameters.startDate).format('YYYY-MM-DD') +
            '&endDate=' +
            moment(parameters.endDate).format('YYYY-MM-DD')
    }

    return axiosInstance.get(url).then((result: AxiosResponse<StatisticsResponse>) => {
        if (result.data) {
            return result.data
        }
        throw Error()
    })
}

export const getGroups = (id: number): Promise<Array<GroupFacebookModel>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    let url = '/establishment/:id/groups'.replace(':id', id.toString())
    return axiosInstance.get(url).then((result: AxiosResponse<Array<GroupFacebookModel>>) => {
        if (result.data) {
            return result.data
        }
        throw Error()
    })
}
