import { NewsModel } from '../../models'

export enum NewsActionTypes {
    SET_NEWS_LIST = 'event/SET_NEWS_LIST',
    SET_NEWS = 'event/SET_NEWS',
    ADD_NEWS = 'event/ADD_NEWS',
    DELETE_NEWS = 'event/DELETE_NEWS',
}

export interface NewsState {
    newsList?: Array<NewsModel>
}
