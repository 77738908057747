import { createAction } from 'typesafe-actions'
import { NewsActionTypes } from './types'
import { prefix } from '../../config/config'
import { NewsModel } from '../../models'

const actionPrefix = '@@' + prefix + '/'

export const setNewsList = createAction(actionPrefix + NewsActionTypes.SET_NEWS_LIST)<Array<NewsModel>>()
export const setNews = createAction(actionPrefix + NewsActionTypes.SET_NEWS)<NewsModel>()
export const addNews = createAction(actionPrefix + NewsActionTypes.ADD_NEWS)<NewsModel>()
export const deleteNews = createAction(actionPrefix + NewsActionTypes.DELETE_NEWS)<number>()
