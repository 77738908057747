import { BusinessHours, DayOfWeek, Establishment } from './types'

export const convertToEstablishment = (data: any): Establishment => {
    if (data.businessHours === null) {
        return data
    }
    const businessHours: BusinessHours = {
        [DayOfWeek.Monday]: data.businessHours['monday'] ?? [],
        [DayOfWeek.Tuesday]: data.businessHours['tuesday'] ?? [],
        [DayOfWeek.Wednesday]: data.businessHours['wednesday'] ?? [],
        [DayOfWeek.Thursday]: data.businessHours['thursday'] ?? [],
        [DayOfWeek.Friday]: data.businessHours['friday'] ?? [],
        [DayOfWeek.Saturday]: data.businessHours['saturday'] ?? [],
        [DayOfWeek.Sunday]: data.businessHours['sunday'] ?? [],
    }

    let type = null
    if (data.type && data.type.id) {
        type = data.type.id
    }

    return {
        ...data,
        businessHours,
        type,
    }
}
