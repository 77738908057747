import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'

export const useCustomerPortal = () => {
    const [inProgress, setInProgress] = useState(false)

    const getCustomerPortal = (): Promise<string> => {
        setInProgress(true)
        return queries
            .getCustomerPortal()
            .then((response) => {
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        getCustomerPortal,
        inProgress,
    }
}
