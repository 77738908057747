import { Reducer } from 'redux'
import { NewsState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { NewsModel } from '../../models'

const initialState: NewsState = {}

const reducer: Reducer<NewsState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setNewsList): {
            const newsList: Array<NewsModel> = action.payload
            return {
                ...state,
                newsList,
            }
        }
        case getType(actions.setNews): {
            const news: NewsModel = action.payload
            let { newsList } = state
            if (newsList !== undefined) {
                const newsIndex = newsList.findIndex((establishment) => establishment.id === news.id)
                if (newsIndex !== -1) {
                    newsList[newsIndex] = {
                        ...news,
                    }
                }

                return {
                    ...state,
                    newsList,
                }
            }
            return {
                ...state,
                newsList,
            }
        }
        case getType(actions.addNews): {
            const news: NewsModel = action.payload
            let { newsList } = state
            if (newsList !== undefined) {
                const eventIndex = newsList.findIndex((currentNews) => currentNews.id === news.id)
                if (eventIndex !== -1) {
                    newsList[eventIndex] = {
                        ...news,
                    }
                } else {
                    newsList = [...newsList, news]
                }

                return {
                    ...state,
                    newsList,
                }
            } else {
                newsList = [
                    {
                        ...news,
                    },
                ]
            }
            return {
                ...state,
                newsList,
            }
        }
        case getType(actions.deleteNews): {
            const eventId: number = action.payload
            let { newsList } = state
            if (newsList !== undefined) {
                const eventIndex = newsList.findIndex((establishment) => establishment.id === eventId)
                if (eventIndex !== -1) {
                    newsList.splice(eventIndex, 1)
                }

                return {
                    ...state,
                    newsList,
                }
            }
            return {
                ...state,
                newsList,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        case getType(actions.signOut): {
            return {
                ...state,
                newsList: undefined,
            }
        }
        default:
            return state
    }
}

export { reducer as newsReducer }
