import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'
import { StatisticsRequest, StatisticsResponse } from '../../resources/establishment/types'
import { useDispatch } from '../redux'
import actions from '../../store/actions'

export const useStatistics = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const getStatistics = (payload: StatisticsRequest): Promise<StatisticsResponse> => {
        setInProgress(true)
        return queries
            .getStatistics(payload)
            .then((response) => {
                dispatch(actions.setStatistics(response))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
    }

    return {
        getStatistics,
        inProgress,
    }
}
