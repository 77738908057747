import { Reducer } from 'redux'
import { EstablishmentState, SetEstablishmentGroupsAction, SetReminderNotebooksAction } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { User } from '../../resources/auth/types'
import {
    CurrentSlateResponse,
    DeleteMenuEntryResponse,
    DeleteSpecialClosingRequest,
    Establishment,
    EstablishmentType,
    MenuEntriesResponse,
    MenuEntry,
    SpecialClosing,
    StatisticsResponse,
} from '../../resources/establishment/types'

const initialState: EstablishmentState = {}

const reducer: Reducer<EstablishmentState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setUser): {
            const user: User = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined) {
                user.establishments.forEach((establishment) => {
                    const establishmentIndex = establishments.findIndex(
                        (currentEstablishment) => currentEstablishment.id === establishment.id,
                    )
                    if (establishmentIndex !== -1) {
                        newEstablishments[establishmentIndex] = {
                            ...establishments[establishmentIndex],
                            ...establishment,
                        }
                    }
                })

                return {
                    ...state,
                    establishments: newEstablishments,
                }
            }

            return {
                ...state,
                establishments: user.establishments || [],
            }
        }
        case getType(actions.setMenuEntries): {
            const menuEntriesResponse: MenuEntriesResponse = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === menuEntriesResponse.id,
                )
                if (establishmentIndex !== -1) {
                    newEstablishments[establishmentIndex].menuEntries = menuEntriesResponse.menuEntries || []
                }

                return {
                    ...state,
                    establishments: newEstablishments,
                }
            }
            return state
        }
        case getType(actions.deleteMenuEntry): {
            const deleteMenuEntryResponse: DeleteMenuEntryResponse = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === deleteMenuEntryResponse.id,
                )
                if (establishmentIndex !== -1) {
                    const menuEntries: Array<MenuEntry> =
                        newEstablishments[establishmentIndex].menuEntries || []
                    const pictureIndex = menuEntries.findIndex(
                        (menuEntry) => menuEntry.id === deleteMenuEntryResponse.pictureId,
                    )
                    if (pictureIndex !== -1) {
                        menuEntries.splice(pictureIndex, 1)
                    }
                }

                return {
                    ...state,
                    establishments: newEstablishments,
                }
            }
            return state
        }
        case getType(actions.setCurrentSlate): {
            const currentSlateResponse: CurrentSlateResponse = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === currentSlateResponse.id,
                )
                if (establishmentIndex !== -1) {
                    newEstablishments[establishmentIndex] = {
                        ...establishments[establishmentIndex],
                        slate: currentSlateResponse.slate || null,
                        lastSlate: currentSlateResponse.lastSlate || null,
                    }
                }

                return {
                    ...state,
                    establishments: newEstablishments,
                }
            }
            return state
        }
        case getType(actions.setReminderNotebooks): {
            const { establishmentId, reminderNotebooks } = action.payload as SetReminderNotebooksAction
            const { establishments } = state
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === establishmentId,
                )
                if (establishmentIndex !== -1) {
                    establishments[establishmentIndex].reminderNotebooks = reminderNotebooks || []
                }

                return {
                    ...state,
                    establishments: establishments,
                }
            }
            return state
        }
        case getType(actions.setEstablishment): {
            const establishmentResponse: Establishment = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === establishmentResponse.id,
                )
                if (establishmentIndex !== -1) {
                    newEstablishments[establishmentIndex] = {
                        ...establishments[establishmentIndex],
                        ...establishmentResponse,
                    }
                }

                return {
                    ...state,
                    establishments: newEstablishments,
                }
            }
            return {
                ...state,
                establishments: [establishmentResponse],
            }
        }
        case getType(actions.setEstablishmentTypes): {
            const establishmentTypes: Array<EstablishmentType> = action.payload

            return {
                ...state,
                establishmentTypes,
            }
        }
        case getType(actions.addSpecialClosing): {
            const specialClosing: SpecialClosing = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === specialClosing.establishment,
                )
                if (establishmentIndex !== -1) {
                    newEstablishments[establishmentIndex] = {
                        ...establishments[establishmentIndex],
                        specialClosing: [
                            ...establishments[establishmentIndex].specialClosing,
                            specialClosing,
                        ],
                    }
                }

                return {
                    ...state,
                    establishments: newEstablishments,
                }
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.deleteSpecialClosing): {
            const delSpecialClosing: DeleteSpecialClosingRequest = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === delSpecialClosing.establishment,
                )
                if (establishmentIndex !== -1) {
                    const specialClosings: Array<SpecialClosing> =
                        establishments[establishmentIndex].specialClosing || []
                    const closingIndex = specialClosings.findIndex(
                        (specialClosing) => specialClosing.id === delSpecialClosing.id,
                    )
                    if (closingIndex !== -1) {
                        specialClosings.splice(closingIndex, 1)
                    }
                    newEstablishments[establishmentIndex] = {
                        ...establishments[establishmentIndex],
                        specialClosing: specialClosings,
                    }
                }

                return {
                    ...state,
                    establishments: newEstablishments,
                }
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.setStatistics): {
            const statistics: StatisticsResponse | undefined = action.payload
            const { establishments } = state
            const newEstablishments = [...(establishments || [])]
            if (establishments !== undefined && statistics !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === statistics.id,
                )
                if (establishmentIndex !== -1) {
                    newEstablishments[establishmentIndex] = {
                        ...establishments[establishmentIndex],
                        lastStatisticsFetchAt: statistics.lastStatisticsFetchAt,
                    }
                }

                return {
                    ...state,
                    statistics: statistics.statistics,
                    newsStatistics: statistics.newsStatistics,
                    establishments: newEstablishments,
                }
            }

            return {
                ...state,
                statistics: undefined,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        case getType(actions.setEstablishmentGroups): {
            const payload: SetEstablishmentGroupsAction = action.payload
            let { establishmentsGroups } = state
            if (establishmentsGroups) {
                establishmentsGroups[payload.establishmentId] = payload.groups
            } else {
                establishmentsGroups = {
                    [payload.establishmentId]: payload.groups,
                }
            }
            return {
                ...state,
                establishmentsGroups,
            }
        }
        case getType(actions.signOut): {
            return {
                ...state,
                establishments: undefined,
                statistics: undefined,
                newsStatistics: undefined,
            }
        }
        default:
            return state
    }
}

export { reducer as establishmentReducer }
