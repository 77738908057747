import { SlateCategory } from '../../resources/slate/types'

export enum SlateActionTypes {
    SET_CATEGORIES = 'slate/SET_CATEGORIES',
    UPDATE_SLATE = 'slate/UPDATE_SLATE',
    SHARE_SLATE = 'slate/SHARE_SLATE',
}

export interface SlateState {
    categories?: Array<SlateCategory>
}
