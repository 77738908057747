import {
    MessagingConversation,
    NewMessagingMessage,
    PostDetails,
    SetMessagingMessage,
    TypeConversation,
} from '../../resources/messages/types'

export type WebsocketAction =
    | 'newMessage'
    | 'updateStatus'
    | 'newConversation'
    | 'updateConversation'
    | 'deleteConversation'
    | 'deleteMessage'
    | 'setStatus'

export enum MessagesActionTypes {
    SET_MESSAGING_CONVERSATION = 'messages/SET_MESSAGING_CONVERSATION',
    PATCH_MESSAGING_CONVERSATION = 'messages/PATCH_MESSAGING_CONVERSATION',
    SET_MESSAGING_MESSAGE = 'messages/SET_MESSAGING_MESSAGE',
    ADD_MESSAGING_MESSAGE = 'messages/ADD_MESSAGING_MESSAGE',
    DELETE_MESSAGING_CONVERSATIONS = 'messages/DELETE_MESSAGING_CONVERSATIONS',
    CLEAN_MESSAGING = 'messages/CLEAN_MESSAGING',
    ADD_MESSAGING_CONVERSATION = 'messages/ADD_MESSAGING_CONVERSATION',
    MESSAGING_CONVERSATION_READY = 'messages/MESSAGING_CONVERSATION_READY',
    DELETE_MESSAGING_MESSAGE = 'messages/DELETE_MESSAGING_MESSAGE',
    HIDE_MESSAGING_MESSAGE = 'messages/HIDE_MESSAGING_MESSAGE',
    DELETE_MESSAGING_CONVERSATION = 'messages/DELETE_MESSAGING_CONVERSATION',
    SET_MESSAGING_STATUS = 'messages/SET_MESSAGING_STATUS',
    UPDATE_MESSAGING_STATUS = 'messages/UPDATE_MESSAGING_STATUS',
    SET_POST_DETAILS = 'messages/SET_POST_DETAILS',
    CREATE_MESSAGING_MESSAGE = 'messages/CREATE_MESSAGING_MESSAGE',
    UPDATE_MESSAGING_CONVERSATION = 'messages/UPDATE_MESSAGING_CONVERSATION',
}

export interface MessagingState {
    status?: MessagingStatus
    convPrivateMessages?: MessagingConversation[]
    convPosts?: MessagingConversation[]
    convReviews?: MessagingConversation[]
    listMessages?: SetMessagingMessage[]
    lastMessages?: NewMessagingMessage[]
    listPostDetails?: PostDetails[]
}

export interface SetMessagingConversation {
    type: TypeConversation
    conversations: MessagingConversation[]
}

export interface WebsocketRequestData<T> {
    action: WebsocketAction
    actionData: T
}

export interface MessagingStatus {
    conversationCount: number
    hasUnreadConversation: boolean
    unreadConversationCount: number
}
