import { SuggPro } from '../../client/suggPro'
import { AxiosResponse } from 'axios'
import {
    FacebookPagesRequest,
    FacebookPagesResponse,
    GoogleAccountsResponse,
    GooglePagesRequest,
    GooglePagesResponse,
} from './types'

export const getFacebookPages = (parameters: FacebookPagesRequest): Promise<FacebookPagesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()

    if (parameters.facebookAccessToken) {
        bodyFormData.append('facebookAccessToken', parameters.facebookAccessToken)
    }
    if (parameters.grantedScopes) {
        bodyFormData.append('grantedScopes', parameters.grantedScopes)
    }

    const url = '/restaurant_owner/facebook/connect/pages'

    return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<FacebookPagesResponse>) => {
        return result.data
    })
}

export const getGooglePages = (parameters: GooglePagesRequest): Promise<GooglePagesResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()

    if (parameters.googleAccessToken && parameters.googleId) {
        bodyFormData.append('googleAccessToken', parameters.googleAccessToken)
        bodyFormData.append('googleId', parameters.googleId)
    }

    const url = '/restaurant_owner/connect/google/locations'

    return axiosInstance.post(url, bodyFormData).then((result: AxiosResponse<GooglePagesResponse>) => {
        return result.data
    })
}

export const getGoogleAccounts = (): Promise<GoogleAccountsResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const url = '/restaurant_owner/connect/google/accounts'

    return axiosInstance.post(url).then((result: AxiosResponse<GoogleAccountsResponse>) => {
        return result.data
    })
}
