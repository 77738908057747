import { AxiosError } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import { generateError } from '../../helpers/errors'
import { mutations } from '../../resources'
import {
    DeleteEstablishmentPictureRequest,
    Establishment,
    EstablishmentPicture,
    EstablishmentPicturesResponse,
    UpdateEstablishmentWebsiteSectionResponse,
} from '../../resources/establishment/types'
import { actions } from '../actions'
import { ApplicationState } from '../reducers'

function* establishmentSaga() {
    yield takeLatest(getType(actions.connectToFacebookPage), connectToFacebookPage)
    yield takeLatest(getType(actions.connectToGooglePage), connectToGooglePage)
    yield takeLatest(getType(actions.deleteEstablishmentPicture), deleteEstablishmentPicture)
    yield takeLatest(getType(actions.setEstablishmentPictures), setEstablishmentPictures)
    yield takeLatest(getType(actions.setEstablishmentWebsiteSection), setEstablishmentWebsiteSection)
    yield takeLatest(getType(actions.signOut), cleanStatistics)
    yield takeLatest(getType(actions.signOut), cleanMessaging)
}

export function* connectToFacebookPage(action: ActionType<typeof actions.connectToFacebookPage>) {
    try {
        const establishment: Establishment = yield call(mutations.connectToFacebookPage, action.payload)
        yield put(actions.setEstablishment(establishment))
    } catch (error) {
        throw generateError(error as AxiosError | undefined)
    }
}

export function* connectToGooglePage(action: ActionType<typeof actions.connectToGooglePage>) {
    try {
        const establishment: Establishment = yield call(mutations.connectToGooglePage, action.payload)
        yield put(actions.setEstablishment(establishment))
    } catch (error) {
        throw generateError(error as AxiosError | undefined)
    }
}

export function* deleteEstablishmentPicture(action: ActionType<typeof actions.deleteEstablishmentPicture>) {
    const deleteEstablishmentPictureRequest: DeleteEstablishmentPictureRequest = action.payload
    const establishments: Array<Establishment> | undefined = yield select(
        ({ suggpro: { establishment } }: ApplicationState) => establishment.establishments,
    )
    if (establishments !== undefined) {
        const establishmentIndex = establishments.findIndex(
            (establishment) => establishment.id === deleteEstablishmentPictureRequest.id,
        )
        if (establishmentIndex !== -1) {
            const pictures: Array<EstablishmentPicture> = establishments[establishmentIndex].pictures || []
            const pictureIndex = pictures.findIndex(
                (picture) => picture.id === deleteEstablishmentPictureRequest.pictureId,
            )
            if (pictureIndex !== -1) {
                pictures.splice(pictureIndex, 1)
            }
        }
        yield put(actions.setEstablishment(establishments[establishmentIndex]))
    }
}

export function* setEstablishmentPictures(action: ActionType<typeof actions.setEstablishmentPictures>) {
    const establishmentPicturesResponse: EstablishmentPicturesResponse = action.payload
    const establishments: Array<Establishment> | undefined = yield select(
        ({ suggpro: { establishment } }: ApplicationState) => establishment.establishments,
    )
    if (establishments !== undefined) {
        const establishmentIndex = establishments.findIndex(
            (establishment) => establishment.id === establishmentPicturesResponse.id,
        )
        if (establishmentIndex !== -1) {
            establishments[establishmentIndex].pictures = establishmentPicturesResponse.pictures || []
        }

        yield put(actions.setEstablishment(establishments[establishmentIndex]))
    }
}

export function* setEstablishmentWebsiteSection(
    action: ActionType<typeof actions.setEstablishmentWebsiteSection>,
) {
    const establishmentWebsiteSectionResponse: UpdateEstablishmentWebsiteSectionResponse = action.payload
    const establishments: Array<Establishment> | undefined = yield select(
        ({ suggpro: { establishment } }: ApplicationState) => establishment.establishments,
    )
    if (establishments !== undefined) {
        const establishmentIndex = establishments.findIndex(
            (establishment) => establishment.id === establishmentWebsiteSectionResponse.id,
        )
        if (establishmentIndex !== -1) {
            const websiteSections = establishments[establishmentIndex].websiteSections
            if (websiteSections) {
                const websiteSectionIndex = websiteSections.findIndex(
                    (websiteSection) =>
                        websiteSection.id === establishmentWebsiteSectionResponse.websiteSection.id,
                )
                if (websiteSectionIndex !== -1) {
                    websiteSections[websiteSectionIndex] = establishmentWebsiteSectionResponse.websiteSection
                } else {
                    websiteSections.push(establishmentWebsiteSectionResponse.websiteSection)
                }
                establishments[establishmentIndex].websiteSections = websiteSections
            } else {
                establishments[establishmentIndex].websiteSections = [
                    establishmentWebsiteSectionResponse.websiteSection,
                ]
            }
        }

        yield put(actions.setEstablishment(establishments[establishmentIndex]))
    }
}

export function* cleanStatistics() {
    yield put(actions.setStatistics(undefined))
}

export function* cleanMessaging() {
    yield put(actions.cleanMessaging())
}

export default establishmentSaga
