import { useSelector } from '../redux'
import { ApplicationState } from '../../store'

export const useEstablishment = (establishmentId: number) => {
    const { establishments } = useSelector(({ suggpro: { establishment } }: ApplicationState) => ({
        establishments: establishment.establishments,
    }))
    if (establishments !== undefined) {
        return establishments.find((establishment) => establishment.id === establishmentId)
    }
    return undefined
}
