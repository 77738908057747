import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'
import { GooglePagesRequest, GooglePagesResponse } from '../../resources/restaurantOwner/types'

export const useGetGooglePages = () => {
    const [inProgress, setInProgress] = useState(false)

    const getGooglePages = (payload: GooglePagesRequest): Promise<GooglePagesResponse> => {
        setInProgress(true)
        return queries
            .getGooglePages(payload)
            .then((response) => {
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        getGooglePages,
        inProgress,
    }
}
