import { useState } from 'react'
import { generateError } from '../../helpers/errors'
import { queries } from '../../resources'
import { GetMessagingConversation, MessagingConversation } from '../../resources/messages/types'
import { setMessagingConversation } from '../../store/messaging/actions'
import { useDispatch } from '../redux'

export const useGetConversations = () => {
    const [inProgress, setInProgress] = useState(false)
    const dispatch = useDispatch()

    const getConversations = (payload: GetMessagingConversation): Promise<MessagingConversation[]> => {
        setInProgress(true)
        return queries
            .getMessagingConversation(payload)
            .then((response) => {
                dispatch(
                    setMessagingConversation({ type: payload.typeConversation, conversations: response }),
                )
                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        getConversations,
        inProgress,
    }
}
