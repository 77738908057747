import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../../helpers/errors'
import { GoogleAccountsResponse } from '../../resources/restaurantOwner/types'

export const useGetGoogleAccounts = () => {
    const [inProgress, setInProgress] = useState(false)

    const getGoogleAccounts = (): Promise<GoogleAccountsResponse> => {
        setInProgress(true)
        return queries
            .getGoogleAccounts()
            .then((response) => {
                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        getGoogleAccounts,
        inProgress,
    }
}
