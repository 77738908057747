import { TokenResponse, User } from '../../resources/auth/types'

export enum AuthActionTypes {
    SET_SIGN_IN_IN_PROGRESS_START = 'auth/SET_SIGN_IN_IN_PROGRESS_START',
    SET_SIGN_IN_IN_PROGRESS_END = 'auth/SET_SIGN_IN_IN_PROGRESS_END',
    SIGN_IN = 'auth/SIGN_IN',
    SIGN_OUT = 'auth/SIGN_OUT',
    SET_USER = 'auth/SET_USER',
    SIGN_IN_WITH_FACEBOOK = 'auth/SIGN_IN_WITH_FACEBOOK',
    SIGN_IN_WITH_FACEBOOK_FAILED = 'auth/SIGN_IN_WITH_FACEBOOK_FAILED',
    VERIFY_EMAIL = 'auth/VERIFY_EMAIL',
    UPDATE_NOTIFICATION_PREFERENCES = 'auth/UPDATE_NOTIFICATION_PREFERENCES',
    SEND_TOKEN = 'auth/SEND_TOKEN',
    OPEN_WEBSOCKET = 'auth/OPEN_WEBSOCKET',
    OPENING_WEBSOCKET = 'auth/OPENING_WEBSOCKET',
    CLOSE_WEBSOCKET = 'auth/CLOSE_WEBSOCKET',
}

export interface AuthState {
    user?: User
    refresh?: string
    access?: string
    signInInProgress: boolean
    websocketOpening?: string
    websocketOpened?: string
}

export interface SignIn extends TokenResponse {
    withProgress?: boolean
}
