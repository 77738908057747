import { useDispatch } from '../redux'
import { useState } from 'react'
import { mutations } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { EventModel, NewsModel } from '../../models'
import { CreateEventRequest } from '../../resources/event/types'

export const useEventCreate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const createEvent = (parameters: CreateEventRequest): Promise<EventModel> => {
        setInProgress(true)
        return mutations
            .createEvent(parameters)
            .then((response) => {
                const news: NewsModel = {
                    ...response,
                    event: {
                        startTime: response.startTime,
                        title: response.title,
                    },
                }
                dispatch(actions.addNews(news))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        createEvent,
        inProgress,
    }
}
