import { Reducer } from 'redux'
import { SlateState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { SlateCategory } from '../../resources/slate/types'

const initialState: SlateState = {}

const reducer: Reducer<SlateState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setCategories): {
            const categories: Array<SlateCategory> = action.payload
            return {
                ...state,
                categories,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        default:
            return state
    }
}

export { reducer as slateReducer }
