import * as authActions from './auth/actions'
import * as sessionActions from './session/actions'
import * as establishmentActions from './establishment/actions'
import * as slateActions from './slate/actions'
import * as restaurantOwnerActions from './restaurantOwner/actions'
import * as stripeActions from './stripe/actions'
import * as newsActions from './news/actions'
import * as messagingActions from './messaging/actions'

export const actions = {
    ...authActions,
    ...sessionActions,
    ...establishmentActions,
    ...slateActions,
    ...restaurantOwnerActions,
    ...stripeActions,
    ...newsActions,
    ...messagingActions,
}

export default actions
