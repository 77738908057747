import { useState } from 'react'
import { generateError } from '../../helpers/errors'
import { mutations } from '../../resources'
import { PostMessage } from '../../resources/messages/types'
import { useDispatch, useSelector } from '../redux'
import { createMessagingMessage } from '../../store/messaging/actions'
import { ApplicationState } from '../../store'
import { getAuthor } from '../../resources/messages/helpers'

export const usePostMessages = () => {
    const dispatch = useDispatch()
    const [inProgressSendMessage, setInProgressSendMessage] = useState(false)
    const { establishments, messagingState } = useSelector(
        ({ suggpro: { establishment, messaging } }: ApplicationState) => ({
            establishments: establishment.establishments,
            messagingState: messaging,
        }),
    )

    const postMessage = (payload: PostMessage): Promise<string | undefined> => {
        setInProgressSendMessage(true)
        return mutations
            .sendMessage(payload)
            .then((response) => {
                dispatch(
                    createMessagingMessage({
                        ...payload,
                        author: getAuthor(payload, establishments, messagingState),
                        messageId: response.data,
                    }),
                )
                return response.data
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgressSendMessage(false)
            })
    }

    return {
        postMessage,
        inProgressSendMessage,
    }
}
