import moment from 'moment'
import { channel } from 'redux-saga'
import { call, delay, fork, put, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import { v4 } from 'uuid'
import { setBearerToken } from '../../client/bearerAuth'
import { SuggPro } from '../../client/suggPro'
import { mutations, queries } from '../../resources'
import { refreshToken } from '../../resources/auth/mutations'
import { TokenResponse, User } from '../../resources/auth/types'
import {
    DeleteConversation,
    DeleteMessage,
    MessagingConversation,
    MessagingMessage,
    NewMessagingMessage,
} from '../../resources/messages/types'
import { actions } from '../actions'
import { MessagingStatus, WebsocketRequestData } from '../messaging/types'
import { ApplicationState } from '../reducers'

function* authSaga() {
    yield takeEvery(getType(actions.signIn), getProfile)
    yield takeLatest(getType(actions.verifyEmail), verifyEmail)

    yield takeLatest(getType(actions.updateNotificationPreferences), updateNotificationPreferences)
    yield takeLatest(getType(actions.sendToken), sendToken)

    try {
        yield takeLatest(getType(actions.signIn), openWebsocket)
        yield fork(watchWebsocketRequestChannel)
        yield fork(watchWebsocketOpeningChannel)
        yield fork(watchWebsocketOpenChannel)
        yield fork(watchWebsocketCloseChannel)
    } catch (e) {
        console.error('Websocket failed:', e)
    }
}

export function* getProfile(action: ActionType<typeof actions.signIn>) {
    // @ts-ignore
    yield spawn(restoreToken, action.payload.access, action.payload.refresh)

    try {
        if (action.payload.withProgress) {
            yield put(actions.setSignInInProgressStart())
        }
        const user: User = yield call(queries.getProfile)
        if (user) {
            yield put(actions.setUser(user))
        } else {
            yield put(actions.signOut())
        }
    } catch (ignore) {
        yield put(actions.signOut())
    } finally {
        if (action.payload.withProgress) {
            yield put(actions.setSignInInProgressEnd())
        }
    }
}

function* restoreToken(access: string, refresh: string) {
    try {
        const refreshedToken: TokenResponse = yield call(setBearerToken, access, refresh)
        yield put(actions.signIn(refreshedToken))
    } catch (error) {
        console.error(error)
        const internalError = error as any
        if (
            'response' in internalError &&
            internalError.response &&
            internalError.response.status === 401 &&
            internalError.response.data &&
            internalError.response.data.detail === 'Authentication credentials were not provided.'
        ) {
            yield put(actions.signOut())
        }
    }
}

export function* verifyEmail() {
    try {
        const user: User = yield select(({ suggpro: { auth } }: ApplicationState) => auth.user)
        if (user) {
            yield put(actions.setUser(user))
        } else {
            yield put(actions.signOut())
        }
    } catch (ignore) {
        yield put(actions.signOut())
    }
}

function* sendToken(action: ActionType<typeof actions.sendToken>) {
    yield call([mutations, 'sendToken'], action.payload)
}

function* updateNotificationPreferences(action: ActionType<typeof actions.updateNotificationPreferences>) {
    const { user } = yield select(({ suggpro: { auth } }: ApplicationState) => ({
        user: auth.user,
    }))
    if (user) {
        user.notificationPreference = { ...action.payload }
        yield put(actions.setUser(user))
    }
}

const websocketRequestChannel = channel()
const websocketOpeningChannel = channel()
const websocketOpenChannel = channel()
const websocketCloseChannel = channel()

function* openWebsocket(action: ActionType<typeof actions.signIn>) {
    const { websocketOpened, websocketOpening } = yield select(({ suggpro: { auth } }: ApplicationState) => ({
        websocketOpened: auth.websocketOpened,
        websocketOpening: auth.websocketOpening,
    }))
    if (!(websocketOpened !== undefined || websocketOpening !== undefined)) {
        yield spawn(openWebsocketConnection, action.payload.access)
    }
}

function* openWebsocketConnection(accessToken: string) {
    const websocketURI = SuggPro.instance.options.websocketUri

    let userAgent = SuggPro.instance.options.appAgent
    if (SuggPro.instance.options.versionName !== '') {
        userAgent += '_' + SuggPro.instance.options.versionName
    }
    if (SuggPro.instance.options.versionNumber !== '') {
        userAgent += '_' + SuggPro.instance.options.versionNumber
    }
    const connectionId = v4()
    websocketOpeningChannel.put(connectionId)
    const socket = new WebSocket(websocketURI + '?user_agent=' + userAgent + '&access_token=' + accessToken)

    // Open Connection
    socket.onopen = () => {
        console.log('Websocket connected')
        websocketOpenChannel.put(connectionId)
    }

    // On Error
    socket.onerror = (ev) => {
        console.log('Websocket error', ev)
        websocketCloseChannel.put(connectionId)
    }

    // Receive Message
    socket.onmessage = (ev) => {
        const message: WebsocketRequestData<any> = JSON.parse(ev.data)
        websocketRequestChannel.put(message)
    }

    // Close Connection
    socket.onclose = () => {
        console.log('Websocket disconnected')
        websocketCloseChannel.put(connectionId)
    }
    yield take(getType(actions.signOut))
    socket.close()
}

export function* watchWebsocketRequestChannel() {
    while (true) {
        const websocketRequest: WebsocketRequestData<MessagingMessage> = yield take(websocketRequestChannel)
        yield fork(handleWebsocketRequestData, websocketRequest)
    }
}

export function* watchWebsocketOpeningChannel() {
    while (true) {
        const connectionId: string = yield take(websocketOpeningChannel)
        yield put(actions.openingWebsocket(connectionId))
    }
}

export function* watchWebsocketOpenChannel() {
    while (true) {
        const connectionId: string = yield take(websocketOpenChannel)
        yield put(actions.openWebsocket(connectionId))
    }
}

export function* watchWebsocketCloseChannel() {
    while (true) {
        const connectionId: string = yield take(websocketCloseChannel)
        yield fork(handleCloseWebsocket, connectionId)
    }
}

function* handleCloseWebsocket(connectionId: string) {
    yield put(actions.closeWebsocket(connectionId))
    yield delay(20000)

    const { refresh, websocketOpened, websocketOpening } = yield select(
        ({ suggpro: { auth } }: ApplicationState) => ({
            refresh: auth.refresh,
            websocketOpened: auth.websocketOpened,
            websocketOpening: auth.websocketOpening,
        }),
    )
    if (refresh && !(websocketOpened !== undefined || websocketOpening !== undefined)) {
        try {
            const refreshedToken: TokenResponse = yield call(refreshToken, { refresh })
            yield put(actions.signIn(refreshedToken))
        } catch (e) {
            console.error(e)
        }
    }
}

export function* handleWebsocketRequestData(websocketData: WebsocketRequestData<any>) {
    // Actions
    switch (websocketData.action) {
        case 'setStatus':
            const messagingStatus: MessagingStatus = websocketData.actionData

            yield put(actions.setMessagingStatus(messagingStatus))
            break
        case 'updateStatus':
            const updateStatus: MessagingStatus = websocketData.actionData

            yield put(actions.updateMessagingStatus(updateStatus))
            break
        case 'newMessage':
            const newMessagingMessage: NewMessagingMessage = {
                ...websocketData.actionData,
                createdAt: moment(websocketData.actionData.createdAt).toDate(),
            }

            // Update the store
            yield put(actions.addMessagingMessage(newMessagingMessage))
            break
        case 'newConversation':
            const newMessagingConversation: MessagingConversation = {
                ...websocketData.actionData,
                createdAt: moment(websocketData.actionData.createdAt).toDate(),
            }

            // Update the store
            yield put(actions.addMessagingConversation(newMessagingConversation))
            break
        case 'updateConversation':
            const updatedMessagingConversation: MessagingConversation = {
                ...websocketData.actionData,
                createdAt: moment(websocketData.actionData.createdAt).toDate(),
            }

            // Update the store
            yield put(actions.updateMessagingConversation(updatedMessagingConversation))
            break
        case 'deleteConversation':
            const conversationDelete: DeleteConversation = websocketData.actionData

            // Update the store
            yield put(actions.deleteMessagingConversation(conversationDelete))
            break
        case 'deleteMessage':
            const messageDeleted: DeleteMessage = websocketData.actionData

            // Update the store
            yield put(actions.deleteMessagingMessage(messageDeleted))
    }
}

export default authSaga
