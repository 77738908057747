import { Reducer } from 'redux'
import { RestaurantOwnerState } from './types'

const initialState: RestaurantOwnerState = {}

const reducer: Reducer<RestaurantOwnerState> = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export { reducer as restaurantOwnerReducer }
