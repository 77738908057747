import { Reducer } from 'redux'
import { SessionState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'

const initialState: SessionState = {
    started: false,
}

const reducer: Reducer<SessionState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.start): {
            const started = action.payload
            return {
                ...state,
                started,
            }
        }
        default:
            return state
    }
}

export { reducer as sessionReducer }
