import actions from '../../store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'
import { UpdateSlateRequest } from '../../resources/slate/types'
import { CurrentSlateResponse } from '../../resources/establishment/types'
import { useDispatch } from '../redux'
import { AxiosError } from 'axios'
import APIError from '../../resources/error'

export const useShareSlate = (
    onFacebookError: (error: APIError) => void = () => {},
    onGoogleError: (error: APIError) => void = () => {},
    onInstagramError: (error: APIError) => void = () => {},
    onTwitterError: (error: APIError) => void = () => {},
) => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)
    const [facebookInProgress, setFacebookInProgress] = useState(false)
    const [googleInProgress, setGoogleInProgress] = useState(false)
    const [instagramInProgress, setInstagramInProgress] = useState(false)
    const [twitterInProgress, setTwitterInProgress] = useState(false)

    const shareSlate = (payload: UpdateSlateRequest): Promise<CurrentSlateResponse> => {
        setInProgress(true)

        return mutations
            .shareSlate(
                payload,
                setFacebookInProgress,
                setGoogleInProgress,
                setInstagramInProgress,
                setTwitterInProgress,
                (error: AxiosError) => onFacebookError(generateError(error)),
                (error: AxiosError) => onGoogleError(generateError(error)),
                (error: AxiosError) => onInstagramError(generateError(error)),
                (error: AxiosError) => onTwitterError(generateError(error)),
            )
            .then((response) => {
                setInProgress(false)
                dispatch(actions.setCurrentSlate(response))
                dispatch(actions.setStatistics(undefined))
                return response
            })
            .catch((error) => {
                setInProgress(false)
                setFacebookInProgress(false)
                setGoogleInProgress(false)
                setInstagramInProgress(false)
                setTwitterInProgress(false)
                throw generateError(error)
            })
    }

    return {
        shareSlate,
        inProgress,
        facebookInProgress,
        googleInProgress,
        instagramInProgress,
        twitterInProgress,
    }
}
