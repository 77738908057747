import { MessagingConversation } from '../messages/types'
import { Slate, SlateCategory } from '../slate/types'

export interface WebsiteSection {
    id?: string
    section: string
    textColor: string | null
    backgroundColor: string | null
    backgroundImage: string | null
}

export interface Establishment {
    id: number
    name: string | null
    description: string | null
    shortDescription: string | null
    email: string | null
    phoneNumber: string | null
    DUNS: string | null
    location: Location
    completeAddress: Address
    menuEntries?: Array<MenuEntry>
    slate?: Slate | null
    lastSlate?: Slate | null
    reminderNotebook: null | 'STANDARD' | 'ENFORCE'
    reminderNotebooks?: Array<ReminderNotebook>
    isFacebookLinked: boolean
    isGoogleLinked: boolean
    photos?: Array<string>
    businessHours: BusinessHours | null
    facebookUrl: string | null
    instagramUrl: string | null
    mealandmeetUrl: string | null
    interactiveGame: string | null
    interactiveGameAdmin: string | null
    website: string | null
    delivery: string | null
    booking: string | null
    facebookPageId: string | null
    instagramPageId: string | null
    instagramUsername: string | null
    facebookSync: boolean
    googleId: string | null
    googleUrl: string | null
    googleSync: boolean
    status: EstablishmentStatus | null
    type: number | null | EstablishmentType
    foodType: Array<string>
    pictures: Array<EstablishmentPicture>
    promotionRedeem: string | null
    hasRedeemCode: boolean
    subscribeState: string
    favoritesCount: number | null
    existingDuns: string | null
    twitterID: string | null
    twitterScreenName: string | null
    isTwitterSignedExpired: boolean | null
    specialClosing: Array<SpecialClosing>
    lastStatisticsFetchAt: Date | null
    uriKey: string | null
    messagingConversation: Array<MessagingConversation> | null
    bookingModule: boolean
    bookingEmail: string | null
    orderModule: boolean
    orderEmail: string | null
    defaultMenuBoardComment: string | null
    defaultMenuBoardCommentUseDefault: boolean
    defaultMenuBoardCategories: Array<SlateCategory> | null
    defaultMenuBoardCategoriesUseLast: boolean
    facebookDeleteFlag: boolean
    twitterDeleteFlag: boolean
    googlePostDeleteFlag: boolean
    googlePhotoDeleteFlag: boolean
    bookingFullDays: Array<string> | null
    isInstagramBusiness: boolean
    remplisVert: boolean
    remplisVertComment: string | null
    websiteBackgroundColorLight?: string | null
    websiteBackgroundColorDark?: string | null
    websiteFont?: string | null
    websiteSections?: WebsiteSection[]
}

export interface EstablishmentPicture {
    id: number
    url: string
    sortOrder: number
    isMainPicture: boolean
}

export interface Address {
    street_1: string | null
    street_2: string | null
    zip_code: string | null
    city: string | null
    country: string | null
}

export interface EstablishmentStatus {
    id: number
    name: string
    value: number
}

export interface EstablishmentType {
    id: number
    name: string
    key: string
}

export interface Location {
    lat: number | null
    lng: number | null
}

export interface MenuEntry {
    id: number
    url: string
    sort_order: number
}

export interface MenuEntriesRequest {
    id: number
}

export interface MenuEntriesResponse {
    id: number
    menuEntries: Array<MenuEntry>
}

export interface CreateMenuEntriesRequest {
    id: number
    files: Array<File>
}

export interface UpdateMenuEntriesRequest {
    id: number
    sortOrders: Array<number>
}

export interface DeleteMenuEntryRequest {
    id: number
    pictureId: number
}

export interface DeleteMenuEntryResponse {
    id: number
    pictureId: number
}

export interface CurrentSlateRequest {
    id: number
}

export interface CurrentSlateResponse {
    id: number
    slate: Slate | null
    lastSlate: Slate | null
}

export interface CreateSlateResponse {
    id: number
    slate: Slate
    lastSlate: Slate
}

export interface CurrentSlatesResponse {
    current: Slate | null
    last: Slate | null
}

export interface ConnectToFacebookPageRequest {
    id: number
    pageId: number | null
    facebookAccessToken?: string
    updateEstablishment: boolean
    facebookSync: boolean
}

export interface ConnectToGooglePageRequest {
    id: number
    idGoogleLocation: string | null
    googleAccessToken?: string
    updateEstablishment: boolean
    googleSync: boolean
}

export type BusinessHours = {
    [day in DayOfWeek]: Array<BusinessHour>
}

export interface BusinessHour {
    startTime: string
    endTime: string
}

export enum DayOfWeek {
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}

export interface EstablishmentUpdate
    extends Omit<Establishment, 'defaultMenuBoardCategories' | 'websiteSections'> {
    defaultMenuBoardCategories: Array<number>
}

export interface UpdateEstablishmentRequest {
    id: number
    establishment: Partial<EstablishmentUpdate>
    nextStatus?:
        | 'SIRET'
        | 'PAYMENT'
        | 'PAID'
        | 'SOCIAL_NETWORK'
        | 'ESTABLISHMENT'
        | 'FOOD'
        | 'LOCATION'
        | 'HOURS'
        | 'PHOTOS'
        | 'DONE'
}

export interface CreateEstablishmentPicturesRequest {
    id: number
    files: Array<File>
}

export interface UpdateEstablishmentPicturesRequest {
    id: number
    sortOrders: Array<number>
    mainPicture: number | undefined
}

export interface UpdateEstablishmentWebsiteSectionRequest extends Omit<WebsiteSection, 'backgroundImage'> {
    establishment: number
    backgroundImage?: string | File | null
}

export interface UpdateEstablishmentWebsiteSectionResponse {
    id: number
    websiteSection: WebsiteSection
}

export interface EstablishmentPicturesResponse {
    id: number
    pictures: Array<EstablishmentPicture>
}

export interface GetTypesRequest {}

export interface EstablishmentPicturesRequest {
    id: number
}

export interface DeleteEstablishmentPictureRequest {
    id: number
    pictureId: number
}

export interface EstablishmentRetrieveStripeCheckoutRequest {
    id: number
    price: string
    coupon?: string
}

export interface EstablishmentUseNumericCheckRequest {
    id: number
    code: string
}

export interface GetEstablishmentRequest {
    id: number
}

export interface GetReminderNotebooksRequest {
    id: number
    date?: string
}

export interface EstablishmentRedeemCodeRequest {
    id: number
    code: string
}

export interface ReminderNotebook {
    givenName: string
    familyName: string
    phone: string
    createdAt: string
}

export interface InitializeConnectToTwitterResponse {
    oauth_token: string
    oauth_token_secret: string
}

export interface TwitterInfoRequest {
    twitterOauthToken: string
    twitterOauthVerifier: string
}

export interface TwitterInfoResponse {
    twitterID: string
    twitterScreenName: string
    twitterToken: string
    twitterTokenSecret: string
}

export interface SpecialClosing {
    id: number
    establishment: number
    label: string
    dateStart: string
    dateEnd: string
}

export interface SpecialClosingRequest {
    establishment: number
    label: string
    dateStart: string
    dateEnd: string
}

export interface SpecialClosingResponse {
    id: number
}

export interface GetSpecialClosing {
    establishment: number
}

export interface DeleteSpecialClosingRequest {
    id: number
    establishment: number
}

export interface DeleteSpecialClosingResponse {
    id: number
}

export interface TwitterInfoResponse {
    twitterID: string
    twitterScreenName: string
    twitterToken: string
    twitterTokenSecret: string
}

export interface StatisticsRequest {
    id: number
    startDate?: Date
    endDate?: Date
}

export interface StatisticsResponse {
    id: number
    lastStatisticsFetchAt: Date | null
    statistics: Array<Statistics<MenuBoardProperty>>
    newsStatistics: Array<Statistics<NewsProperty>>
}

type OptionsFlags<K> = {
    [Property in keyof K]: K[Property]
}

export type MenuBoardProperty = {
    menuboard: MenuBoardBase
}

export type NewsProperty = {
    news: NewsBase
}

export type Statistics<T> = {
    id: number
    socialMedia: 'facebook' | 'instagram' | 'googlemybusiness' | 'googlemybusiness-post' | 'twitter'
    views: number
    likes: number
    comments: number
    shares: number
    date: Date
} & OptionsFlags<T>

export interface MenuBoardBase {
    id: number
    createdAt: Date
}

export interface NewsBase {
    id: number
    createdAt: Date
    endTime: Date
}
