import { useState } from 'react'
import { EstablishmentRetrieveStripeCheckoutRequest } from '../../resources/establishment/types'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'

export const useEstablishmentRetrieveStripeCheckout = () => {
    const [inProgress, setInProgress] = useState(false)

    const establishmentRetrieveStripeCheckout = (
        payload: EstablishmentRetrieveStripeCheckoutRequest,
    ): Promise<string> => {
        setInProgress(true)
        return mutations
            .establishmentRetrieveStripeCheckout(payload)
            .then((response) => {
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        establishmentRetrieveStripeCheckout,
        inProgress,
    }
}
