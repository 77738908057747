import { useDispatch } from '../redux'
import { useState } from 'react'
import { queries } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'
import { NewsModel } from '../../models'

export const useNewsGetList = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const getNewsList = (): Promise<Array<NewsModel>> => {
        setInProgress(true)
        return queries
            .getNewsList()
            .then((response) => {
                dispatch(actions.setNewsList(response))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        getNewsList,
        inProgress,
    }
}
