import { combineReducers } from 'redux'
import { AuthState } from './auth/types'
import { authReducer } from './auth/reducer'
import { SessionState } from './session/types'
import { sessionReducer } from './session/reducer'
import { establishmentReducer } from './establishment/reducer'
import { EstablishmentState } from './establishment/types'
import { SlateState } from './slate/types'
import { slateReducer } from '../store/slate/reducer'
import { RestaurantOwnerState } from './restaurantOwner/types'
import { restaurantOwnerReducer } from '../store/restaurantOwner/reducer'
import { StripeState } from './stripe/types'
import { stripeReducer } from '../store/stripe/reducer'
import { NewsState } from './news/types'
import { newsReducer } from './news/reducer'
import { MessagingState } from './messaging/types'
import { messagesReducer } from './messaging/reducer'

export interface ApplicationState {
    suggpro: SuggProState
}

export interface SuggProState {
    auth: AuthState
    session: SessionState
    establishment: EstablishmentState
    slate: SlateState
    restaurantOwner: RestaurantOwnerState
    stripe: StripeState
    news: NewsState
    messaging: MessagingState
}

export const suggProReducers = {
    auth: authReducer,
    session: sessionReducer,
    establishment: establishmentReducer,
    slate: slateReducer,
    restaurantOwner: restaurantOwnerReducer,
    stripe: stripeReducer,
    news: newsReducer,
    messaging: messagesReducer,
}

export const suggProReducer = combineReducers<SuggProState>(suggProReducers)
