import { SendForgottenPasswordCodeRequest, SetPasswordRequest } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'

export const useForgottenPassword = () => {
    const [sendForgottenPasswordInProgress, setSendForgottenPasswordInProgress] = useState(false)
    const [updateForgotPasswordInProgress, setUpdateForgotPasswordInProgress] = useState(false)

    const sendForgottenPasswordCode = (payload: SendForgottenPasswordCodeRequest): Promise<undefined> => {
        setSendForgottenPasswordInProgress(true)
        return mutations
            .sendForgottenPasswordCode(payload)
            .then(() => {
                setSendForgottenPasswordInProgress(false)
                return undefined
            })
            .catch((error) => {
                setSendForgottenPasswordInProgress(false)
                throw generateError(error)
            })
    }

    const updateForgotPassword = (payload: SetPasswordRequest): Promise<undefined> => {
        setUpdateForgotPasswordInProgress(true)
        return mutations
            .updateForgotPassword(payload)
            .then(() => {
                setUpdateForgotPasswordInProgress(false)
                return undefined
            })
            .catch((error) => {
                setUpdateForgotPasswordInProgress(false)
                throw generateError(error)
            })
    }

    return {
        sendForgottenPasswordCode,
        updateForgotPassword,
        sendForgottenPasswordInProgress,
        updateForgotPasswordInProgress,
    }
}
