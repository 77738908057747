import APIError, {
    AccountExist,
    CodeAlreadyRedeem,
    FacebookAccountExist,
    FacebookPageNotLinked,
    FacebookSessionExpired,
    FacebookTokenExpired,
    FacebookTokenRequired,
    FacebookWrongPermissions,
    GoogleAccountExist,
    GoogleOfflineCodeExpired,
    GooglePageNotLinked,
    GoogleTokenExpired,
    GoogleTokenRequired,
    MessagingConversationNotFound,
    MessagingMessageNotSend,
    Sugg1144AccountExist,
    Sugg1144FacebookAccountExist,
    Sugg1144GoogleAccountExist,
    SuggProAccountExist,
    SuggProFacebookAccountExist,
    SuggProGoogleAccountExist,
    TwitterExpired,
    TwitterTemporary,
    WrongCode,
} from '../resources/error'
import { AxiosError } from 'axios'

export const generateError = (error: AxiosError | undefined = undefined) => {
    if (error === undefined) {
        return new APIError()
    }
    const response = error.response
    if (response && response.status) {
        switch (response.status) {
            case 400: {
                const data = response.data
                if (data !== undefined && data.detail !== undefined) {
                    switch (data.detail) {
                        case 'account_exist':
                            return new AccountExist(data)
                        case 'facebook_account_exist':
                            return new FacebookAccountExist(data)
                        case 'google_account_exist':
                            return new GoogleAccountExist(data)
                        case 'no_facebook_page_found':
                            return new FacebookWrongPermissions(data)
                        case 'facebook_page_not_linked':
                            return new FacebookPageNotLinked(data)
                        case 'google_page_not_linked':
                            return new GooglePageNotLinked(data)
                        case 'sugg1144_account_exist':
                            return new Sugg1144AccountExist(data)
                        case 'suggpro_account_exist':
                            return new SuggProAccountExist(data)
                        case 'suggpro_google_account_exist':
                            return new SuggProGoogleAccountExist(data)
                        case 'suggpro_facebook_account_exist':
                            return new SuggProFacebookAccountExist(data)
                        case 'sugg1144_google_account_exist':
                            return new Sugg1144GoogleAccountExist(data)
                        case 'sugg1144_facebook_account_exist':
                            return new Sugg1144FacebookAccountExist(data)
                        case 'wrong_code':
                            return new WrongCode(data)
                        case 'twitter_expired':
                            return new TwitterExpired(data)
                        case 'twitter_temporary':
                            return new TwitterTemporary(data)
                        case 'messaging_message_not_send':
                            return new MessagingMessageNotSend(data)
                    }
                }
                if (data !== undefined && data.googleOfflineCode !== undefined) {
                    return new GoogleOfflineCodeExpired(data)
                }
                break
            }
            case 401: {
                const data = response.data
                if (data !== undefined && data.detail !== undefined) {
                    switch (data.detail) {
                        case 'facebook_token_expired':
                            return new FacebookTokenExpired(data)
                        case 'facebook_wrong_permissions':
                        case 'facebook_permission_denied':
                            return new FacebookWrongPermissions(data)
                        case 'facebook_token_required':
                            return new FacebookTokenRequired(data)
                        case 'facebook_session_expired':
                            return new FacebookSessionExpired(data)
                        case 'google_token_expired':
                            return new GoogleTokenExpired(data)
                        case 'google_token_required':
                            return new GoogleTokenRequired(data)
                    }
                }
                break
            }
            case 402: {
                const data = response.data
                if (data !== undefined && data.detail !== undefined) {
                    switch (data.detail) {
                        case 'code_already_redeem':
                            return new CodeAlreadyRedeem(data)
                    }
                }
                break
            }
            case 404: {
                const data = response.data
                if (data !== undefined && data.detail !== undefined) {
                    switch (data.detail) {
                        case 'messaging_conversation_not_found':
                            return new MessagingConversationNotFound(data)
                    }
                }
                break
            }
        }
    }

    const errorType = error.code
    const message = error.message
    const data = error.response?.data
    const status = error.response?.status
    return new APIError(errorType, message, data, status, error)
}
