import { createAction } from 'typesafe-actions'
import { SlateActionTypes } from './types'
import { prefix } from '../../config/config'
import { SlateCategory, UpdateSlateRequest } from '../../resources/slate/types'

const actionPrefix = '@@' + prefix + '/'

export const setCategories = createAction(actionPrefix + SlateActionTypes.SET_CATEGORIES)<
    Array<SlateCategory>
>()

export const updateSlate = createAction(actionPrefix + SlateActionTypes.UPDATE_SLATE)<UpdateSlateRequest>()
export const shareSlate = createAction(actionPrefix + SlateActionTypes.SHARE_SLATE)<UpdateSlateRequest>()
