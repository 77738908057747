import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'
import {
    InitializeConnectToTwitterResponse,
    TwitterInfoRequest,
    TwitterInfoResponse,
} from '../../resources/establishment/types'

export const useConnectToTwitter = () => {
    const [initializeConnectToTwitterInProgress, setInitializeConnectToTwitterInProgress] = useState(false)
    const [getTwitterInfoInProgress, setGetTwitterInfoInProgress] = useState(false)

    const initializeConnectToTwitter = (): Promise<InitializeConnectToTwitterResponse> => {
        setInitializeConnectToTwitterInProgress(true)
        return mutations
            .initializeConnectToTwitter()
            .then(({ data }) => {
                setInitializeConnectToTwitterInProgress(false)
                if (data) {
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInitializeConnectToTwitterInProgress(false)
                throw generateError(error)
            })
    }

    const getTwitterInfo = (parameters: TwitterInfoRequest): Promise<TwitterInfoResponse> => {
        setGetTwitterInfoInProgress(true)
        return mutations
            .getTwitterInfo(parameters)
            .then(({ data }) => {
                setGetTwitterInfoInProgress(false)
                if (data) {
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setGetTwitterInfoInProgress(false)
                throw generateError(error)
            })
    }

    return {
        initializeConnectToTwitter,
        initializeConnectToTwitterInProgress,
        getTwitterInfo,
        getTwitterInfoInProgress,
    }
}
