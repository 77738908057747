import moment from 'moment/moment'
import { Reducer } from 'redux'
import { getType } from 'typesafe-actions'
import { User } from '../../resources/auth/types'
import { Establishment } from '../../resources/establishment/types'
import { allConversations, inConversations, updateStatus } from '../../resources/messages/helpers'
import {
    DeleteConversation,
    DeleteMessage,
    MessagingConversation,
    MessagingMessage,
    NewMessagingMessage,
    PostDetails,
    PostMessageWithAuthor,
    SetMessagingMessage,
} from '../../resources/messages/types'
import actions from '../actions'
import { MessagingState, MessagingStatus, SetMessagingConversation } from './types'

const initialState: MessagingState = {}

const reducer: Reducer<MessagingState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setMessagingConversation): {
            const conversations: SetMessagingConversation = action.payload
            switch (conversations.type) {
                case 'private_message':
                    return {
                        ...state,
                        convPrivateMessages: conversations.conversations,
                    }
                case 'post':
                    return {
                        ...state,
                        convPosts: conversations.conversations,
                    }
                case 'review':
                    return {
                        ...state,
                        convReviews: conversations.conversations,
                    }
            }

            return {
                ...state,
            }
        }
        case getType(actions.patchMessagingConversation): {
            const conversation: MessagingConversation = action.payload

            switch (conversation.typeConversation) {
                case 'private_message':
                    const { convPrivateMessages } = state
                    const newConversationsPrivateMessages = [...(convPrivateMessages || [])]
                    if (convPrivateMessages !== undefined) {
                        const conversationIndex = convPrivateMessages.findIndex(
                            (conversationInStore) => conversationInStore.id === conversation.id,
                        )
                        if (conversationIndex !== -1) {
                            newConversationsPrivateMessages[conversationIndex] = {
                                ...convPrivateMessages[conversationIndex],
                                ...conversation,
                            }
                        }
                        return {
                            ...state,
                            convPrivateMessages: newConversationsPrivateMessages,
                        }
                    }
                    return {
                        ...state,
                        convPrivateMessages: [conversation],
                    }
                case 'post':
                    const { convPosts } = state
                    const newConversationsPosts = [...(convPosts || [])]

                    if (convPosts !== undefined) {
                        const conversationIndex = convPosts.findIndex(
                            (conversationInStore) => conversationInStore.id === conversation.id,
                        )
                        if (conversationIndex !== -1) {
                            newConversationsPosts[conversationIndex] = {
                                ...convPosts[conversationIndex],
                                ...conversation,
                            }
                        }
                        return {
                            ...state,
                            convPosts: newConversationsPosts,
                        }
                    }
                    return {
                        ...state,
                        convPosts: [conversation],
                    }
                case 'review':
                    const { convReviews } = state
                    const newConversationsReviews = [...(convReviews || [])]

                    if (convReviews !== undefined) {
                        const conversationIndex = convReviews.findIndex(
                            (conversationInStore) => conversationInStore.id === conversation.id,
                        )
                        if (conversationIndex !== -1) {
                            newConversationsReviews[conversationIndex] = {
                                ...convReviews[conversationIndex],
                                ...conversation,
                            }
                        }
                        return {
                            ...state,
                            convReviews: newConversationsReviews,
                        }
                    }
                    return {
                        ...state,
                        convReviews: [conversation],
                    }
            }
            return state
        }
        case getType(actions.setMessagingMessage): {
            const newSetMessagingMessage: SetMessagingMessage = action.payload
            const { listMessages } = state

            const updateListMessages = [...(listMessages || [])]
            if (updateListMessages) {
                updateListMessages.push(newSetMessagingMessage)
            }

            return {
                ...state,
                listMessages: updateListMessages,
            }
        }
        case getType(actions.addMessagingMessage): {
            const newMessage: NewMessagingMessage = action.payload
            const { listMessages, convPrivateMessages, convPosts, convReviews, lastMessages } = state
            let updatedState

            const newListMessages = [...(listMessages || [])]
            const conversationIndex = newListMessages.findIndex(
                (message) => message.conversation === newMessage.conversation,
            )
            if (conversationIndex !== -1) {
                // Find if message is already in listMessages
                const existingMessageIndex = newListMessages[conversationIndex].messages.findIndex(
                    (message) => message.messageId === newMessage.messageId,
                )
                if (existingMessageIndex !== -1) {
                    newListMessages[conversationIndex].messages[existingMessageIndex] = newMessage
                } else {
                    newListMessages[conversationIndex].messages.push(newMessage)
                }
            }

            const updateLastMessages = [...(lastMessages || [])]
            updateLastMessages.unshift(newMessage)

            // Update conversation with the last message
            const listAllConversations = allConversations(state)
            // Get the conversation of the new message
            const conversationOfNewMessage = listAllConversations?.find(
                (conv) => conv.id === newMessage.conversation,
            )

            if (conversationOfNewMessage) {
                switch (conversationOfNewMessage.typeConversation) {
                    case 'private_message':
                        const updateConvPrivateMessages = [...(convPrivateMessages || [])]
                        const indexConvPrivateMessage = convPrivateMessages?.findIndex(
                            (conv) => conv.id === conversationOfNewMessage.id,
                        )
                        if (
                            convPrivateMessages !== undefined &&
                            indexConvPrivateMessage !== undefined &&
                            indexConvPrivateMessage !== -1
                        ) {
                            updateConvPrivateMessages[indexConvPrivateMessage] = {
                                ...convPrivateMessages[indexConvPrivateMessage],
                                lastMessageText: newMessage.messageText,
                                updatedAt: newMessage.createdAt,
                                unread: true,
                            }
                        }
                        updatedState = {
                            ...state,
                            listMessages: newListMessages,
                            lastMessages: updateLastMessages,
                            convPrivateMessages: updateConvPrivateMessages,
                        }
                        break
                    case 'post':
                        const updateConvPosts = [...(convPosts || [])]
                        const indexConvPost = convPosts?.findIndex(
                            (conv) => conv.id === conversationOfNewMessage.id,
                        )
                        if (convPosts !== undefined && indexConvPost !== undefined && indexConvPost !== -1) {
                            updateConvPosts[indexConvPost] = {
                                ...convPosts[indexConvPost],
                                lastMessageText: newMessage.messageText,
                                updatedAt: newMessage.createdAt,
                                unread: true,
                            }
                        }
                        updatedState = {
                            ...state,
                            listMessages: newListMessages,
                            lastMessages: updateLastMessages,
                            convPosts: updateConvPosts,
                        }
                        break
                    case 'review':
                        const updateConvReviews = [...(convReviews || [])]
                        const indexConvReview = convReviews?.findIndex(
                            (conv) => conv.id === conversationOfNewMessage.id,
                        )
                        if (
                            convReviews !== undefined &&
                            indexConvReview !== undefined &&
                            indexConvReview !== -1
                        ) {
                            updateConvReviews[indexConvReview] = {
                                ...convReviews[indexConvReview],
                                lastMessageText: newMessage.messageText,
                                updatedAt: newMessage.createdAt,
                                unread: true,
                            }
                        }
                        updatedState = {
                            ...state,
                            listMessages: newListMessages,
                            lastMessages: updateLastMessages,
                            convReviews: updateConvReviews,
                        }
                        break
                }

                return {
                    ...updatedState,
                }
            } else {
                return {
                    ...state,
                    listMessages: newListMessages,
                    lastMessages: updateLastMessages,
                }
            }
        }
        case getType(actions.cleanMessaging): {
            return initialState
        }
        case getType(actions.deleteMessagingConversations): {
            const { prev_state, next_state } = action.payload
            const establishment_prev_state: Establishment = prev_state
            const establishment_next_state: Establishment = next_state
            const { convPrivateMessages, convPosts, convReviews, listMessages, listPostDetails } = state
            let newConvPrivateMessages: MessagingConversation[] | undefined = convPrivateMessages
            let newConvPosts: MessagingConversation[] | undefined = convPosts
            let newConvReviews: MessagingConversation[] | undefined = convReviews
            let newListMessages: SetMessagingMessage[] | undefined = listMessages
            let newListPostDetails: PostDetails[] | undefined = listPostDetails

            if (establishment_next_state.facebookPageId !== establishment_prev_state.facebookPageId) {
                newConvPrivateMessages = newConvPrivateMessages?.filter(
                    (conv) => !(conv.typePlatform === 'facebook'),
                )
                newConvPosts = newConvPosts?.filter((conv) => !(conv.typePlatform === 'facebook'))
                newConvReviews = newConvReviews?.filter((conv) => !(conv.typePlatform === 'facebook'))

                const listAllConversations = allConversations(state)
                // Update listMessages
                newListMessages = newListMessages?.filter((setMsg) =>
                    inConversations(listAllConversations || [], setMsg.conversation),
                )
                // Update listPostDetails
                newListPostDetails = newListPostDetails?.filter((setPostDetails) =>
                    inConversations(listAllConversations || [], setPostDetails.conversation),
                )
            }
            if (establishment_next_state.instagramPageId !== establishment_prev_state.instagramPageId) {
                newConvPrivateMessages = newConvPrivateMessages?.filter(
                    (conv) => !(conv.typePlatform === 'instagram'),
                )
                newConvPosts = newConvPosts?.filter((conv) => !(conv.typePlatform === 'instagram'))
                newConvReviews = newConvReviews?.filter((conv) => !(conv.typePlatform === 'instagram'))

                const listAllConversations = allConversations(state)
                newListMessages = newListMessages?.filter((setMsg) =>
                    inConversations(listAllConversations || [], setMsg.conversation),
                )
            }
            if (establishment_next_state.twitterID !== establishment_prev_state.twitterID) {
                newConvPrivateMessages = newConvPrivateMessages?.filter(
                    (conv) => !(conv.typePlatform === 'twitter'),
                )
                newConvPosts = newConvPosts?.filter((conv) => !(conv.typePlatform === 'twitter'))
                newConvReviews = newConvReviews?.filter((conv) => !(conv.typePlatform === 'twitter'))

                const listAllConversations = allConversations(state)
                // Update listMessages
                newListMessages = newListMessages?.filter((setMsg) =>
                    inConversations(listAllConversations || [], setMsg.conversation),
                )
                // Update listPostDetails
                newListPostDetails = newListPostDetails?.filter((setPostDetails) =>
                    inConversations(listAllConversations || [], setPostDetails.conversation),
                )
            }
            if (establishment_next_state.googleId !== establishment_prev_state.googleId) {
                newConvPrivateMessages = newConvPrivateMessages?.filter(
                    (conv) => !(conv.typePlatform === 'google'),
                )
                newConvPosts = newConvPosts?.filter((conv) => !(conv.typePlatform === 'google'))
                newConvReviews = newConvReviews?.filter((conv) => !(conv.typePlatform === 'google'))

                const listAllConversations = allConversations(state)
                // Update listMessages
                newListMessages = newListMessages?.filter((setMsg) =>
                    inConversations(listAllConversations || [], setMsg.conversation),
                )
                // Update listPostDetails
                newListPostDetails = newListPostDetails?.filter((setPostDetails) =>
                    inConversations(listAllConversations || [], setPostDetails.conversation),
                )
            }
            return {
                ...state,
                convPrivateMessages: newConvPrivateMessages,
                convPosts: newConvPosts,
                convReviews: newConvReviews,
                listMessages: newListMessages,
                listPostDetails: newListPostDetails,
            }
        }
        case getType(actions.setUser): {
            const user: User = action.payload
            const { convPrivateMessages, convPosts, convReviews, listMessages } = state
            let newConvPrivateMessages: MessagingConversation[] | undefined = convPrivateMessages
            let newConvPosts: MessagingConversation[] | undefined = convPosts
            let newConvReviews: MessagingConversation[] | undefined = convReviews
            let newListMessages: SetMessagingMessage[] | undefined = listMessages

            // Browse user establishments and remove conversations and message for unsigned service
            const listEstablishment: Array<Establishment> = user.establishments
            listEstablishment.forEach((establishment) => {
                // Remove Facebook (and instagram) conversations and messages
                if (!user.isFacebookSigned) {
                    newConvPrivateMessages = newConvPrivateMessages?.filter(
                        (conv) =>
                            !(conv.typePlatform === 'facebook' || conv.typePlatform === 'instagram') &&
                            conv.establishment === establishment.id,
                    )
                    newConvPosts = newConvPosts?.filter(
                        (conv) =>
                            !(conv.typePlatform === 'facebook' || conv.typePlatform === 'instagram') &&
                            conv.establishment === establishment.id,
                    )
                    newConvReviews = newConvReviews?.filter(
                        (conv) =>
                            !(conv.typePlatform === 'facebook' || conv.typePlatform === 'instagram') &&
                            conv.establishment === establishment.id,
                    )
                    const listAllConversations = allConversations(state)
                    newListMessages = newListMessages?.filter((setMsg) =>
                        inConversations(listAllConversations || [], setMsg.conversation),
                    )
                }

                // Remove Google conversations and messages
                if (!user.isGoogleSigned) {
                    newConvPrivateMessages = newConvPrivateMessages?.filter(
                        (conv) =>
                            !(conv.typePlatform === 'google') && conv.establishment === establishment.id,
                    )
                    newConvPosts = newConvPosts?.filter(
                        (conv) =>
                            !(conv.typePlatform === 'google') && conv.establishment === establishment.id,
                    )
                    newConvReviews = newConvReviews?.filter(
                        (conv) =>
                            !(conv.typePlatform === 'google') && conv.establishment === establishment.id,
                    )
                    const listAllConversations = allConversations(state)
                    newListMessages = newListMessages?.filter((setMsg) =>
                        inConversations(listAllConversations || [], setMsg.conversation),
                    )
                }
            })

            const updatedState = {
                ...state,
                convPrivateMessages: newConvPrivateMessages,
                convPosts: newConvPosts,
                convReviews: newConvReviews,
                listMessages: newListMessages,
            }

            return {
                ...updatedState,
                status: updateStatus(state),
            }
        }
        case getType(actions.addMessagingConversation): {
            const newConversation: MessagingConversation = action.payload
            const { convPrivateMessages, convPosts, convReviews } = state

            const updatedConvPrivateMessages = [...(convPrivateMessages || [])]
            const updatedConvPosts = [...(convPosts || [])]
            const updatedConvReviews = [...(convReviews || [])]

            switch (newConversation.typeConversation) {
                case 'private_message':
                    updatedConvPrivateMessages.unshift(newConversation)
                    return {
                        ...state,
                        convPrivateMessages: updatedConvPrivateMessages,
                    }
                case 'post':
                    updatedConvPosts.unshift(newConversation)
                    return {
                        ...state,
                        convPosts: updatedConvPosts,
                    }
                case 'review':
                    updatedConvReviews.unshift(newConversation)
                    return {
                        ...state,
                        convReviews: updatedConvReviews,
                    }
            }

            return state
        }
        case getType(actions.deleteMessagingMessage): {
            const messageDeleted: DeleteMessage = action.payload
            const { listMessages, convPrivateMessages, convPosts, convReviews } = state
            const updatedConvPrivateMessages = [...(convPrivateMessages || [])]
            const updatedConvPosts = [...(convPosts || [])]
            const updatedConvReviews = [...(convReviews || [])]

            const updatedListMessages = (listMessages || []).map((currentMessages) => {
                if (currentMessages.conversation === messageDeleted.conversation) {
                    // Is the last message of the conversation, then update conv
                    const indexMessageToDelete = currentMessages.messages.findIndex(
                        (message) => message.messageId === messageDeleted.messageId,
                    )
                    if (indexMessageToDelete === currentMessages.messages.length - 1) {
                        // Update conv with the new last message
                        const indexConvPrivateMessage = updatedConvPrivateMessages.findIndex(
                            (conv) => conv.id === messageDeleted.conversation,
                        )
                        if (indexConvPrivateMessage !== -1) {
                            if (indexMessageToDelete - 1 === -1) {
                                updatedConvPrivateMessages[
                                    indexConvPrivateMessage
                                ].lastMessageText = undefined
                            } else {
                                updatedConvPrivateMessages[indexConvPrivateMessage].lastMessageText =
                                    currentMessages.messages[indexMessageToDelete - 1].messageText
                            }
                        }

                        const indexConvPosts = updatedConvPosts.findIndex(
                            (conv) => conv.id === messageDeleted.conversation,
                        )
                        if (indexConvPosts !== -1) {
                            if (indexMessageToDelete - 1 === -1) {
                                updatedConvPosts[indexConvPosts].lastMessageText = undefined
                            } else {
                                updatedConvPrivateMessages[indexConvPosts].lastMessageText =
                                    currentMessages.messages[indexMessageToDelete - 1].messageText
                            }
                        }

                        const indexConvReviews = updatedConvReviews.findIndex(
                            (conv) => conv.id === messageDeleted.conversation,
                        )
                        if (indexConvReviews !== -1) {
                            if (indexMessageToDelete - 1 === -1) {
                                updatedConvReviews[indexConvReviews].lastMessageText = undefined
                            } else {
                                updatedConvPrivateMessages[indexConvReviews].lastMessageText =
                                    currentMessages.messages[indexMessageToDelete - 1].messageText
                            }
                        }
                    }

                    currentMessages.messages = currentMessages.messages.filter(
                        (currentMessages) => currentMessages.messageId !== messageDeleted.messageId,
                    )
                }
                return currentMessages
            })

            return {
                ...state,
                convPrivateMessages: updatedConvPrivateMessages,
                convPosts: updatedConvPosts,
                convReviews: updatedConvReviews,
                listMessages: updatedListMessages,
            }
        }
        case getType(actions.hideMessagingMessage): {
            const messageDeleted: DeleteMessage = action.payload
            const { listMessages, convPrivateMessages, convPosts, convReviews } = state
            const updatedConvPrivateMessages = [...(convPrivateMessages || [])]
            const updatedConvPosts = [...(convPosts || [])]
            const updatedConvReviews = [...(convReviews || [])]

            const updatedListMessages = (listMessages || []).map((currentMessages) => {
                if (currentMessages.conversation === messageDeleted.conversation) {
                    // Is the last message of the conversation, then update conv
                    const indexMessageToDelete = currentMessages.messages.findIndex(
                        (message) => message.messageId === messageDeleted.messageId,
                    )
                    if (indexMessageToDelete === currentMessages.messages.length - 1) {
                        // Update conv with the new last message
                        const indexConvPrivateMessage = updatedConvPrivateMessages.findIndex(
                            (conv) => conv.id === messageDeleted.conversation,
                        )
                        if (indexConvPrivateMessage !== -1) {
                            if (indexMessageToDelete - 1 === -1) {
                                updatedConvPrivateMessages[
                                    indexConvPrivateMessage
                                ].lastMessageText = undefined
                            } else {
                                updatedConvPrivateMessages[indexConvPrivateMessage].lastMessageText =
                                    currentMessages.messages[indexMessageToDelete - 1].messageText
                            }
                        }

                        const indexConvPosts = updatedConvPosts.findIndex(
                            (conv) => conv.id === messageDeleted.conversation,
                        )
                        if (indexConvPosts !== -1) {
                            if (indexMessageToDelete - 1 === -1) {
                                updatedConvPosts[indexConvPosts].lastMessageText = undefined
                            } else {
                                updatedConvPrivateMessages[indexConvPosts].lastMessageText =
                                    currentMessages.messages[indexMessageToDelete - 1].messageText
                            }
                        }

                        const indexConvReviews = updatedConvReviews.findIndex(
                            (conv) => conv.id === messageDeleted.conversation,
                        )
                        if (indexConvReviews !== -1) {
                            if (indexMessageToDelete - 1 === -1) {
                                updatedConvReviews[indexConvReviews].lastMessageText = undefined
                            } else {
                                updatedConvPrivateMessages[indexConvReviews].lastMessageText =
                                    currentMessages.messages[indexMessageToDelete - 1].messageText
                            }
                        }
                    }

                    currentMessages.messages[indexMessageToDelete].hidden = true
                }
                return currentMessages
            })

            return {
                ...state,
                convPrivateMessages: updatedConvPrivateMessages,
                convPosts: updatedConvPosts,
                convReviews: updatedConvReviews,
                listMessages: updatedListMessages,
            }
        }
        case getType(actions.deleteMessagingConversation): {
            const conversationDeleted: DeleteConversation = action.payload
            const {
                convPrivateMessages,
                convPosts,
                convReviews,
                listMessages,
                lastMessages,
                listPostDetails,
            } = state
            let updatedState = state

            // Clean up listMessage, lastMessage and listPostDetails
            let updatedListMessages = [...(listMessages || [])]
            updatedListMessages = updatedListMessages.filter(
                (setMessage) => setMessage.conversation !== conversationDeleted.conversationId,
            )
            let updatedLastMessages = [...(lastMessages || [])]
            updatedLastMessages = updatedLastMessages.filter(
                (lastMessage) => lastMessage.conversation !== conversationDeleted.conversationId,
            )
            let updatedListPostDetails = [...(listPostDetails || [])]
            updatedListPostDetails = updatedListPostDetails.filter(
                (postDetails) => postDetails.conversation !== conversationDeleted.conversationId,
            )

            switch (conversationDeleted.typeConversation) {
                case 'private_message':
                    let updatedConvPrivateMessages = [...(convPrivateMessages || [])]
                    updatedConvPrivateMessages = updatedConvPrivateMessages.filter(
                        (conv) => conv.id !== conversationDeleted.conversationId,
                    )

                    updatedState = {
                        convPrivateMessages: updatedConvPrivateMessages,
                    }
                    break
                case 'post':
                    let updatedConvPosts = [...(convPosts || [])]
                    updatedConvPosts = updatedConvPosts.filter(
                        (conv) => conv.id !== conversationDeleted.conversationId,
                    )

                    updatedState = {
                        convPosts: updatedConvPosts,
                    }
                    break
                case 'review':
                    let updatedConvReviews = [...(convReviews || [])]
                    updatedConvReviews = updatedConvReviews.filter(
                        (conv) => conv.id !== conversationDeleted.conversationId,
                    )

                    updatedState = {
                        convReviews: updatedConvReviews,
                    }
                    break
            }

            return {
                ...state,
                ...updatedState,
                listMessages: updatedListMessages,
                lastMessages: updatedLastMessages,
                listPostDetails: updatedListPostDetails,
            }
        }
        case getType(actions.setMessagingStatus): {
            const messagingStatus: MessagingStatus = action.payload

            return {
                ...state,
                status: messagingStatus,
            }
        }
        case getType(actions.updateMessagingStatus): {
            const updatedStatus: MessagingStatus = action.payload

            return {
                ...state,
                status: updatedStatus,
            }
        }
        case getType(actions.setPostDetails): {
            const postDetail: PostDetails = action.payload
            const { listPostDetails } = state
            const updatedListPostDetails = [...(listPostDetails || [])]

            updatedListPostDetails.push(postDetail)

            return {
                ...state,
                listPostDetails: updatedListPostDetails,
            }
        }
        case getType(actions.createMessagingMessage): {
            const postMessage: PostMessageWithAuthor = action.payload
            const { listMessages, convReviews } = state
            const updatedListMessage = [...(listMessages || [])]

            // Create new MessagingMessage
            const createMessage: MessagingMessage = {
                messageId: postMessage.messageId,
                conversation: postMessage.conversation,
                messageText: postMessage.text,
                parentId: postMessage.parentId,
                author: postMessage.author,
                createdAt: moment().toDate(),
                medias: [],
                hidden: false,
            }

            // Find conversation in setMessagingMessage
            const indexConversation = updatedListMessage.findIndex(
                (setMessagingMessage) => setMessagingMessage.conversation === postMessage.conversation,
            )

            // If exist
            if (indexConversation !== -1) {
                const updatedMessages = updatedListMessage[indexConversation].messages
                // Google review case
                const conversationReview = convReviews?.find((conv) => conv.id === postMessage.conversation)
                if (conversationReview && conversationReview.typePlatform === 'google') {
                    updatedMessages.pop()
                }

                updatedMessages.push(createMessage)
                updatedListMessage[indexConversation].messages = updatedMessages
            } else {
                // Create setMessagingMessage
                const setMessagingMessage: SetMessagingMessage = {
                    conversation: postMessage.conversation,
                    messages: [createMessage],
                }
                updatedListMessage.push(setMessagingMessage)
            }

            return {
                ...state,
                listMessages: updatedListMessage,
            }
        }
        case getType(actions.updateMessagingConversation): {
            const updatedConversation: MessagingConversation = action.payload
            const { convPrivateMessages, convPosts, convReviews } = state
            let conversationIndex: number | undefined

            switch (updatedConversation.typeConversation) {
                case 'private_message':
                    const updatedConvPrivateMessages = [...(convPrivateMessages || [])]
                    conversationIndex = updatedConvPrivateMessages.findIndex(
                        (conv) => conv.id === updatedConversation.id,
                    )
                    if (conversationIndex !== undefined && conversationIndex !== -1) {
                        updatedConvPrivateMessages[conversationIndex] = updatedConversation
                        return {
                            ...state,
                            convPrivateMessages: updatedConvPrivateMessages,
                        }
                    }
                    break
                case 'post':
                    const updatedConvPosts = [...(convPosts || [])]
                    conversationIndex = updatedConvPosts.findIndex(
                        (conv) => conv.id === updatedConversation.id,
                    )
                    if (conversationIndex !== undefined && conversationIndex !== -1) {
                        updatedConvPosts[conversationIndex] = updatedConversation
                        return {
                            ...state,
                            convPosts: updatedConvPosts,
                        }
                    }
                    break
                case 'review':
                    const updatedConvReviews = [...(convReviews || [])]
                    updatedConvReviews.findIndex((conv) => conv.id === updatedConversation.id)
                    if (conversationIndex !== undefined && conversationIndex !== -1) {
                        updatedConvReviews[conversationIndex] = updatedConversation
                        return {
                            ...state,
                            convReviews: updatedConvReviews,
                        }
                    }
                    break
            }

            return state
        }
        default:
            return state
    }
}

export { reducer as messagesReducer }
