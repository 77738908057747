import * as authMutations from './auth/mutations'
import * as establishmentMutations from './establishment/mutations'
import * as slateMutations from './slate/mutations'
import * as eventMutations from './event/mutations'
import * as newsMutations from './news/mutations'
import * as messagesMutations from './messages/mutations'

import * as authQueries from './auth/queries'
import * as establishmentQueries from './establishment/queries'
import * as slateQueries from './slate/queries'
import * as restaurantOwnerQueries from './restaurantOwner/queries'
import * as stripeQueries from './stripe/queries'
import * as newsQueries from './news/queries'
import * as messagesQueries from './messages/queries'

export const mutations = {
    ...authMutations,
    ...establishmentMutations,
    ...slateMutations,
    ...eventMutations,
    ...newsMutations,
    ...messagesMutations,
}

export const queries = {
    ...authQueries,
    ...establishmentQueries,
    ...slateQueries,
    ...restaurantOwnerQueries,
    ...stripeQueries,
    ...newsQueries,
    ...messagesQueries,
}
