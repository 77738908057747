import { SuggPro } from '../../client/suggPro'
import {
    CheckEmailRequest,
    NotificationsPreferenceUpdateRequest,
    NotificationTokenRequest,
    RefreshTokenRequest,
    RenewFacebookSignInRequest,
    RenewGoogleSignInRequest,
    ResendVerifyEmailRequest,
    SendForgottenPasswordCodeRequest,
    SetPasswordRequest,
    SignInByEmailRequest,
    SignInWithFacebookRequest,
    SignInWithGoogleRequest,
    SignUpRequest,
    TokenResponse,
    UpdateUserRequest,
    User,
    VerifyEmailRequest,
} from './types'
import { AxiosResponse } from 'axios'
import { convertToUser } from './helpers'
import { NotificationPreferenceModel } from '../../models'

export const signInByEmail = (parameters: SignInByEmailRequest): Promise<AxiosResponse<TokenResponse>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const sha512 = SuggPro.getSha512()
    return sha512(parameters.password).then((encodedPassword) => {
        const bodyFormData = new FormData()

        bodyFormData.append('email', parameters.email)
        bodyFormData.append('password', encodedPassword.toString())

        return axiosInstance.post('/auth/login', bodyFormData)
    })
}

export const signInWithFacebook = (
    parameters: SignInWithFacebookRequest,
): Promise<AxiosResponse<TokenResponse>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()

    bodyFormData.append('userId', parameters.userId)
    bodyFormData.append('accessToken', parameters.accessToken)

    return axiosInstance.post('/auth/login/facebook/', bodyFormData)
}

export const signInWithGoogle = (
    parameters: SignInWithGoogleRequest,
): Promise<AxiosResponse<TokenResponse>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()

    bodyFormData.append('email', parameters.email)
    bodyFormData.append('idToken', parameters.idToken)
    bodyFormData.append('id', parameters.id)

    return axiosInstance.post('/auth/login/google/', bodyFormData)
}

export const signUp = (parameters: SignUpRequest): Promise<AxiosResponse<TokenResponse>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const sha512 = SuggPro.getSha512()
    return sha512(parameters.password).then((encodedPassword) => {
        const bodyFormData = new FormData()

        bodyFormData.append('email', parameters.email)
        bodyFormData.append('country', parameters.country)
        bodyFormData.append('password', encodedPassword)
        bodyFormData.append('firstName', parameters.firstName)
        bodyFormData.append('lastName', parameters.lastName)
        if (parameters.phoneNumber) {
            bodyFormData.append('phoneNumber', parameters.phoneNumber)
        }
        if (parameters.facebookAccessToken) {
            bodyFormData.append('facebookAccessToken', parameters.facebookAccessToken)
        }
        if (parameters.googleOfflineCode) {
            bodyFormData.append('googleOfflineCode', parameters.googleOfflineCode)
        }

        return axiosInstance.post('/user/sign-up', bodyFormData)
    })
}

export const renewFacebookSignIn = (parameters: RenewFacebookSignInRequest): Promise<User> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    bodyFormData.append('facebookAccessToken', parameters.facebookAccessToken)
    if (parameters.grantedScopes) {
        bodyFormData.append('grantedScopes', parameters.grantedScopes)
    }

    return axiosInstance.post('/user/facebook/connect', bodyFormData).then((result: AxiosResponse<User>) => {
        return convertToUser(result.data)
    })
}

export const renewGoogleSignIn = (parameters: RenewGoogleSignInRequest): Promise<User> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()
    bodyFormData.append('googleOfflineCode', parameters.googleOfflineCode)
    if (parameters.mode) {
        bodyFormData.append('mode', parameters.mode)
    }

    return axiosInstance.post('/user/google/connect', bodyFormData).then((result: AxiosResponse<User>) => {
        return convertToUser(result.data)
    })
}

export const verifyEmail = (parameters: VerifyEmailRequest): Promise<AxiosResponse<undefined>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()

    bodyFormData.append('login', parameters.email)
    bodyFormData.append('code', parameters.code)

    return axiosInstance.post('/auth/login/code', bodyFormData)
}

export const resendVerifyEmail = (
    parameters: ResendVerifyEmailRequest,
): Promise<AxiosResponse<undefined>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()

    bodyFormData.append('login', parameters.email)
    bodyFormData.append('allowRegistration', 'null')

    return axiosInstance.post('/auth/login/check', bodyFormData)
}

export const sendForgottenPasswordCode = (
    parameters: SendForgottenPasswordCodeRequest,
): Promise<AxiosResponse<undefined>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const bodyFormData = new FormData()

    bodyFormData.append('login', parameters.email)

    return axiosInstance.post('/auth/login/forgot-password', bodyFormData)
}

export const updateForgotPassword = (parameters: SetPasswordRequest): Promise<AxiosResponse<undefined>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    const sha512 = SuggPro.getSha512()
    return sha512(parameters.newPassword).then((encodedPassword) => {
        const bodyFormData = new FormData()

        bodyFormData.append('login', parameters.email)
        bodyFormData.append('code', parameters.code)
        bodyFormData.append('newPassword', encodedPassword)

        return axiosInstance.post('/auth/login/update-forgot-password', bodyFormData)
    })
}

const sha512IfValue = (value?: string) => {
    const sha512 = SuggPro.getSha512()
    if (value) {
        return sha512(value)
    }
    return new Promise<string | undefined>((resolve) => resolve(undefined))
}

export const updateUser = (parameters: UpdateUserRequest): Promise<User> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return sha512IfValue(parameters.password).then((encodedPassword) => {
        const bodyFormData = new FormData()

        if (parameters.email) {
            bodyFormData.append('email', parameters.email)
        }
        if (parameters.firstName) {
            bodyFormData.append('firstName', parameters.firstName)
        }
        if (parameters.lastName) {
            bodyFormData.append('lastName', parameters.lastName)
        }
        if (parameters.phoneNumber) {
            bodyFormData.append('phoneNumber', parameters.phoneNumber)
        }
        if (parameters.country) {
            bodyFormData.append('country', parameters.country)
        }
        if (parameters.googleID) {
            bodyFormData.append('googleID', parameters.googleID)
        }
        if (encodedPassword) {
            bodyFormData.append('password', encodedPassword)
        }

        return axiosInstance.patch('/user/update', bodyFormData).then((result: AxiosResponse<User>) => {
            return convertToUser(result.data)
        })
    })
}

export const checkEmail = (parameters: CheckEmailRequest): Promise<AxiosResponse<undefined>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance.post('/user/check', parameters, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const updateNotificationsPreference = (
    parameters: NotificationsPreferenceUpdateRequest,
): Promise<AxiosResponse<NotificationPreferenceModel>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance.put('/restaurant_owner/notification-preferences-update', parameters, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const sendToken = (parameters: NotificationTokenRequest): Promise<AxiosResponse<void>> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance.post('/notifications/devices', parameters, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const dissociateFacebook = (): Promise<User> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance
        .post(
            '/user/facebook/dissociate',
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        .then((result: AxiosResponse<User>) => {
            return convertToUser(result.data)
        })
}

export const dissociateGoogle = (): Promise<User> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance
        .post(
            '/user/google/dissociate',
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        .then((result: AxiosResponse<User>) => {
            return convertToUser(result.data)
        })
}

export const refreshToken = (parameters: RefreshTokenRequest): Promise<TokenResponse> => {
    const axiosInstance = SuggPro.getAxiosInstance()

    return axiosInstance
        .post('/auth/token/refresh', parameters, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<TokenResponse>) => {
            return result.data
        })
}
