import { useState } from 'react'
import actions from '../../store/actions'
import { useDispatch } from '../redux'

export const useSignOut = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const signOut = (): Promise<boolean> => {
        setInProgress(true)
        return new Promise<boolean>((resolve) => {
            dispatch(actions.signOut())
            setInProgress(false)
            resolve(true)
        })
    }

    return {
        signOut,
        inProgress,
    }
}
