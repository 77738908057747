import { useState } from 'react'
import { generateError } from '../../helpers/errors'
import { mutations } from '../../resources'
import {
    UpdateEstablishmentWebsiteSectionRequest,
    UpdateEstablishmentWebsiteSectionResponse,
} from '../../resources/establishment/types'
import actions from '../../store/actions'
import { useDispatch } from '../redux'

export const useEstablishmentWebsiteSectionUpdate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const updateEstablishmentWebsiteSection = (
        payload: UpdateEstablishmentWebsiteSectionRequest,
    ): Promise<UpdateEstablishmentWebsiteSectionResponse> => {
        setInProgress(true)
        return mutations
            .updateEstablishmentWebsiteSection(payload)
            .then((response) => {
                dispatch(actions.setEstablishmentWebsiteSection(response))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        updateEstablishmentWebsiteSection,
        inProgress,
    }
}
