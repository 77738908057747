import { RenewGoogleSignInRequest, User } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../../helpers/errors'
import actions from '../../store/actions'
import { useDispatch } from '../redux'

export const useRenewGoogleSignIn = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const renewGoogleSignIn = (payload: RenewGoogleSignInRequest): Promise<User> => {
        setInProgress(true)
        return mutations
            .renewGoogleSignIn(payload)
            .then((data) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.setUser(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        renewGoogleSignIn,
        inProgress,
    }
}
