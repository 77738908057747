import { useDispatch } from '../redux'
import { useState } from 'react'
import { mutations } from '../../resources'
import actions from '../../store/actions'
import { generateError } from '../../helpers/errors'

export const useNewsDelete = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const deleteNews = (id: number): Promise<void> => {
        setInProgress(true)
        return mutations
            .deleteNews(id)
            .then((response) => {
                dispatch(actions.deleteNews(id))
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        deleteNews,
        inProgress,
    }
}
